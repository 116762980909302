import {AfterViewInit, AfterContentInit, Component, ViewChild, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {
    ChartComponent,
} from 'ng-apexcharts';
import {Dashboard} from '../../model/dashboard/dashboard';
import {UserService} from '../../service/user.service';
import {
    CardBudgetDashboard,
    CardCompetenceDashboard,
    CardConsultDashboard,
    CardGQL,
    CardQuery,
    CardSuggestionDashboard,
    Country,
    GetCountriesByUserGQL,
    GetCountriesByUserQuery,
    GetCountriesGQL,
    GetCountriesQuery,
    GetProductLinesGQL,
    GetProductsByLineGQL,
    GetProductsByLineQuery,
    GetProductsGQL, GetRankingByCountryGQL, GetRankingByCountryQuery,
    GetRankingGQL,
    GetRankingQuery,
    GetUnScoredTotalGQL,
    GetUnScoredTotalQuery,
    ListQuartersGQL,
    ListQuartersQuery,
    Product,
    ProductLine, ProductLineRankingDto,
    ProductLinesGQL,
    ProductLinesQuery,
    ProductsByLinesGQL,
    ProductsByLinesQuery,
    ProductsQuery,
    QuarterDto,
    RankingDto,
    SearchConsultAll,
    SearchProductLine,
    SearchProductLinesByCountryGQL,
    SearchProductLinesByCountryQuery,
    SearchStatisticInput,
    StatisticCountGQL,
    StatisticCountQuery,
    StatisticDashboard,
    TopBudgetDashboard,
    TopCompetenceDashboard,
    TopConsultDashboard,
    TopGQL,
    TopQuery,
    TopSuggestionDashboard,
    UnScoredTotalDto,
    User,
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {environment} from '../../../environments/environment';
import {IDropdownSettings, ListItem} from 'ng-multiselect-dropdown/multiselect.model';
import moment from 'moment';

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit, AfterContentInit {
    @ViewChild('chart') chart: ChartComponent;

    user = new User();

    statistics: StatisticDashboard = new StatisticDashboard();
    statisticsFilterFront: SearchStatisticInput = new SearchStatisticInput();
    statisticsFilter: SearchStatisticInput = new SearchStatisticInput();
    statisticsQuery: QueryRef<StatisticCountQuery>;

    topConsultDashboard: TopConsultDashboard[] = [];
    topSuggestionDashboard: TopSuggestionDashboard[] = [];
    topCompetenceDashboard: TopCompetenceDashboard[] = [];
    topBudgetDashboard: TopBudgetDashboard[] = [];
    topQuery: QueryRef<TopQuery>;

    cardConsultDashboard: CardConsultDashboard = new CardConsultDashboard();
    cardSuggestionDashboard: CardSuggestionDashboard = new CardSuggestionDashboard();
    cardCompetenceDashboard: CardCompetenceDashboard = new CardCompetenceDashboard();
    cardBudgetDashboard: CardBudgetDashboard = new CardBudgetDashboard();
    cardsQuery: QueryRef<CardQuery>;

    chartDataBarPrimary = {
        type: 'ColumnChart',
        data: [
            ['', 0]
        ],
        options: {
            isStacked: true,
            tooltip: {isHtml: true},
            legend: {position: 'none'},
            colors: ['#0d2e5d', '#6488ba', '#3e6baa', '#2f5a95', '#3374d0', '#6f9fe2', '#476da2'],
            annotations: {
                textStyle: {
                    color: 'black',
                    fontSize: 10,
                    fontFamily: 'arial',
                },
                alwaysOutside: true
            },
            chartArea: {
                right: 0,
                left: 10,
                width: '100%',
                // top: 10,
                // bottom: 30,
                // height: '100%'
            },
            vAxis: {
                textPosition: 'none',
                gridlines: {color: '#f8f8f8'},
                // minValue: 15
            },
            hAxis: {
                textStyle: {color: '#8e8f92', fontName: 'Arial', fontSize: 10}
            }
        },
        height: 270
    };
    chartDataBarSecondary = {
        type: 'ColumnChart',
        data: [
            ['', 0]
        ],
        options: {
            isStacked: true,
            tooltip: {isHtml: true},
            legend: {position: 'none'},
            colors: ['#0d2e5d', '#6488ba', '#3e6baa', '#2f5a95', '#3374d0', '#6f9fe2', '#476da2'],
            annotations: {
                textStyle: {
                    color: 'black',
                    fontSize: 10,
                    fontFamily: 'arial',
                },
                alwaysOutside: true
            },
            chartArea: {
                right: 0,
                left: 10,
                width: '100%',
                // top: 10,
                // bottom: 30,
                // height: '100%'
            },
            vAxis: {
                textPosition: 'none',
                gridlines: {color: '#f8f8f8'},
                // minValue: 15
            },
            hAxis: {
                textStyle: {color: '#8e8f92', fontName: 'Arial', fontSize: 10}
            }
        },
        height: 270
    };
    chartDataBarTertiary = {
        type: 'ColumnChart',
        data: [
            ['', 0]
        ],
        options: {/**/
            isStacked: true,
            tooltip: {isHtml: true},
            legend: {position: 'none'},
            colors: ['#0d2e5d', '#6488ba', '#3e6baa', '#2f5a95', '#3374d0', '#6f9fe2', '#476da2'],
            annotations: {
                textStyle: {
                    color: 'black',
                    fontSize: 10,
                    fontFamily: 'arial',
                },
                alwaysOutside: true
            },
            chartArea: {
                right: 0,
                left: 10,
                width: '100%',
                // top: 10,
                // bottom: 30,
                // height: '100%'
            },
            vAxis: {
                textPosition: 'none',
                gridlines: {color: '#f8f8f8'},
                // minValue: 15
            },
            hAxis: {
                textStyle: {color: '#8e8f92', fontName: 'Arial', fontSize: 10}
            }
        },
        height: 270
    };
    chartDataBarQuartenary = {
        type: 'ColumnChart',
        columnNames: ['Produto', 'Quantidade'],
        data: [
            ['', 0],
        ],
        options: {
            isStacked: true,
            tooltip: {isHtml: true},
            legend: {position: 'none'},
            colors: ['#0d2e5d', '#6488ba', '#3e6baa', '#2f5a95', '#3374d0', '#6f9fe2', '#476da2'],
            annotations: {
                textStyle: {
                    color: 'black',
                    fontSize: 10,
                    fontFamily: 'arial',
                },
                alwaysOutside: true
            },
            chartArea: {
                right: 0,
                left: 10,
                width: '100%',
                // top: 10,
                // bottom: 30,
                // height: '100%'
            },
            vAxis: {
                textPosition: 'none',
                gridlines: {color: '#f8f8f8'},
                // minValue: 15
            },
            hAxis: {
                textStyle: {color: '#8e8f92', fontName: 'Arial', fontSize: 10}
            }
        },
        height: 270
    };

    likesChartConsult = {
        type: 'BarChart',
        data: [
            ['Positivo', 0, 0],
            ['Negativo', 0, 0]
        ],
        columnNames: ['Nome', 'Positivo', 'Negativo'],
        options: {
            vAxis: {
                minValue: 0
            },
            isStacked: true,
            colors: ['#0d2e5d', '#30568b', '#6488ba'],
            legend: 'none',
        },
        height: 100
    };
    likesChartSuggestion = {
        type: 'BarChart',
        data: [
            ['Positivo', 0, 0],
            ['Negativo', 0, 0]
        ],
        columnNames: ['Nome', 'Positivo', 'Negativo'],
        options: {
            vAxis: {
                minValue: 0
            },
            isStacked: true,
            colors: ['#0d2e5d', '#30568b', '#6488ba'],
            legend: 'none',
        },
        height: 100
    };

    productQuery: QueryRef<ProductsByLinesQuery>;
    productsQuery: QueryRef<ProductsQuery>;
    product: Array<Product>;

    productsByLineQuery: QueryRef<GetProductsByLineQuery>;
    products: Array<Product> = [];

    productLinesQuery: QueryRef<ProductLinesQuery>;
    productLinesArr: Array<ProductLine> = [];

    getRankingQuery: QueryRef<GetRankingQuery>;
    rankingArr: Array<RankingDto> = [];

    getRankingByCountryQuery: QueryRef<GetRankingByCountryQuery>;
    rankingByCountryArr: Array<ProductLineRankingDto> = [];

    listQuartersQuery: QueryRef<ListQuartersQuery>;
    listQuartersArr: Array<QuarterDto> = [];

    getUnScoredTotalQuery: QueryRef<GetUnScoredTotalQuery>;
    getUnScoredTotalDto = new UnScoredTotalDto();

    filterL = new SearchProductLine();

    countriesUser: Array<Country>;
    countryUserQuery: QueryRef<GetCountriesByUserQuery>;

    // COUNTRY
    countries: Array<Country>;
    countryQuery: QueryRef<GetCountriesQuery>;
    // lineQuery: QueryRef<ProductLinesQuery>;
    // line: Array<ProductLine>;
    lines: Array<ProductLine> = [];
    linesQuery: QueryRef<SearchProductLinesByCountryQuery>;
    filterCountries: Country[] = [];

    dropSettings: IDropdownSettings;

    filter = new SearchConsultAll();

    lineRankingModel: any = '';
    selectedQuarter: any = '';

    userCountrySpecific : any;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                // public dashboardService: DashboardService,
                private productsByLinesGQL: ProductsByLinesGQL,
                public userService: UserService,
                public translate: TranslateService,
                public statisticCountGQL: StatisticCountGQL,
                public getCountriesGQL: GetCountriesGQL,
                public getCountriesByUserGQL: GetCountriesByUserGQL,
                public getRankingGQL: GetRankingGQL,
                public getRankingByCountryGQL: GetRankingByCountryGQL,
                public searchProductLinesByCountryGQL: SearchProductLinesByCountryGQL,
                private getProductsByLineGQL: GetProductsByLineGQL,
                private productLines: GetProductLinesGQL,
                private productLinesRankingGQL: ProductLinesGQL,
                private listQuartersGQL: ListQuartersGQL,
                private getUnScoredTotalGQL: GetUnScoredTotalGQL,
                private getProductsGQL: GetProductsGQL,
                public topGQL: TopGQL,
                public cardGQL: CardGQL
    ) {
        super(router, loading, translate);
        this.statisticsFilterFront.start = moment().startOf('day').subtract(30, 'days').utcOffset(0, true).format('DD/MM/YYYY');
        this.statisticsFilterFront.end = moment().endOf('day').utcOffset(0, true).format('DD/MM/YYYY');
    }

    ngAfterContentInit(): void {

    }

    ngOnInit() {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;

                this.userCountrySpecific = this.user?.countries?.some(c => c.name.toLowerCase() === 'brazil');

                if (!this.isNullOrUndefined(this.user.profile)) {
                    this.dropSettings = {
                        singleSelection: this.user.profile !== 'admin',
                        idField: 'id',
                        textField: 'name',
                        enableCheckAll: this.user.profile === 'admin',
                        selectAllText: 'Marcar todos',
                        unSelectAllText: 'Desmarcar todos',
                        itemsShowLimit: 8,
                        allowSearchFilter: true
                    };
                }

                if (this.user.profile === 'admin') {
                    this.getProductsLineRanking();
                    this.getListQuarter();

                    //INICIA PRE-FILTRADO
                    this.listQuartersGQL.watch().valueChanges.subscribe(({ data }) => {
                        this.listQuartersArr = data.listQuarters as Array<QuarterDto>;
                        if (this.listQuartersArr.length > 0) {
                            this.selectedQuarter = this.listQuartersArr[this.listQuartersArr.length - 1].quarter;
                            this.getRankingByCountry(this.selectedQuarter);
                        }
                    });

                }
            }, error: err => super.onError(err)
        });

        // this.getProductLineName();
        // this.getProducts();
        this.getCountriesByUser();

        // this.getCountries();
    }

    ngAfterViewInit(): void {

    }

    getLinesByCountries() {
        this.filterL.countries = this.filterCountries.map(e => e.id);
        this.linesQuery = this.searchProductLinesByCountryGQL.watch({id: this.filterL.countries});
        this.linesQuery.valueChanges.subscribe(({data}) => {
            this.lines = data.searchProductLinesByCountry as Array<ProductLine>;
        });
        if (this.statisticsFilter.country?.length > 1) {
            this.statisticsFilter.line = '';
            this.statisticsFilter.product = '';
        } else {
            return this.statisticsFilter.line && this.statisticsFilter.country;
        }
    }

    getProductsByLine() {
        this.productsByLineQuery = this.getProductsByLineGQL.watch({
            lines: this.statisticsFilter.line
        });
        this.productsByLineQuery.valueChanges.subscribe(({data}) => {
            this.products = data.productsByLines as Array<Product>;
        });
    }

    getProductsLineRanking() {
        this.productLinesQuery = this.productLinesRankingGQL.watch();
        this.productLinesQuery.valueChanges.subscribe(({data}) => {
            this.productLinesArr = data.productLines as Array<ProductLine>;
            if (this.productLinesArr.length > 0) {
                this.lineRankingModel = this.productLinesArr[0].id;
                this.getRanking(this.lineRankingModel, this.selectedQuarter);
            }
        });
    }

    getListQuarter() {
        this.listQuartersQuery = this.listQuartersGQL.watch();
        this.listQuartersQuery.valueChanges.subscribe(({data}) => {
            this.listQuartersArr = data.listQuarters as Array<QuarterDto>;
            if (this.listQuartersArr.length > 0) {
                this.selectedQuarter = this.listQuartersArr[0].quarter;
                this.getRanking(this.lineRankingModel, this.selectedQuarter);
            }
        });
    }

    getRanking(lineId: string, quarter: any) {
        if (lineId && quarter) {
            this.getRankingQuery = this.getRankingGQL.watch({productLineId: lineId, quarter: quarter});
            this.getRankingQuery.valueChanges.subscribe(({data}) => {
                this.rankingArr = data.getRanking as Array<RankingDto>;
            });
        }
    }

    getRankingByCountry(quarter: any) {
        if (quarter) {
            this.getRankingByCountryQuery = this.getRankingByCountryGQL.watch({quarter: quarter});
            this.getRankingByCountryQuery.valueChanges.subscribe(({data}) => {
                this.rankingByCountryArr = data.getRankingByCountry as Array<ProductLineRankingDto>;
            });
        }
    }

    getUnScoredTotal(lineId: string, quarter: any) {
        if (lineId && quarter) {
            this.getUnScoredTotalQuery = this.getUnScoredTotalGQL.watch({productLineId: lineId, quarter: quarter});
            this.getUnScoredTotalQuery.valueChanges.subscribe(({data}) => {
                this.getUnScoredTotalDto = data.getUnScoredTotal as UnScoredTotalDto;
            });
        }
    }

    //
    // getProductLineName() {
    //     this.lineQuery = this.productLines.watch();
    //     this.lineQuery.valueChanges.subscribe(({data}) => {
    //         this.line = data.productLines as Array<ProductLine>;
    //     });
    // }

    // getCountries() {
    //     this.countryQuery = this.getCountriesGQL.watch();
    //     this.countryQuery.valueChanges.subscribe(({data}) => {
    //         this.countries = data.countries as Array<Country>;
    //     });
    // }

    getCountriesByUser() {
        this.countryUserQuery = this.getCountriesByUserGQL.watch();
        this.countryUserQuery.valueChanges.subscribe(({data}) => {
            this.countriesUser = data.countriesByUser as Array<Country>;
            if (!this.isNullOrUndefined(this.countriesUser)) {
                this.filterCountries = [this.countriesUser[0]];
            }
            this.statisticsFilter.country = this.isNullOrUndefined(this.filterCountries) ? '' : this.filterCountries[0].id;
            this.setFilter();
            this.getStatistics();
            this.getTop();
            this.getCards();
        });
    }


    setFilter() {
        const start = moment(this.statisticsFilterFront.start, 'DD/MM/YYYY');
        const end = moment(this.statisticsFilterFront.end, 'DD/MM/YYYY');
        this.statisticsFilter.start = moment(start).startOf('day').subtract(30, 'days').utcOffset(0, true).format();
        this.statisticsFilter.end = moment(end).endOf('day').utcOffset(0, true).format();
        // this.statisticsFilter.product = '';
        // this.setDatePickerSingle('js-start', (start, end, label) => {
        //     this.statisticsFilter.start = start.utcOffset(0, true).format();
        // });
        //
        // this.setDatePickerSingle('js-end', (start, end, label) => {
        //     this.statisticsFilter.end = end.utcOffset(0, true).format();
        // });
    }

    onFilter() {
        if (this.user.profile !== 'admin' && this.user.profile !== 'admin-local' && this.filterCountries.length === 0) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCountry')}`);
            return;
        }
        this.setFilter();
        this.statisticsFilter.country = this.isNullOrUndefined(this.filterCountries) ? '' : this.filterCountries[0].id;
        this.statisticsQuery.refetch({input: this.statisticsFilter});
        this.topQuery.refetch({input: this.statisticsFilter});
        this.cardsQuery.refetch({input: this.statisticsFilter});
    }

    getStatistics() {
        this.statisticsQuery = this.statisticCountGQL.watch({input: this.statisticsFilter});
        this.statisticsQuery.valueChanges.subscribe(({data}) => {
            this.statistics = data.statisticCount as StatisticDashboard;
        });
    }

    getTop() {
        this.topQuery = this.topGQL.watch({input: this.statisticsFilter});
        this.topQuery.valueChanges.subscribe(({data}) => {
            this.topConsultDashboard = data.topConsultCount as TopConsultDashboard[];
            this.topSuggestionDashboard = data.topSuggestionCount as TopSuggestionDashboard[];
            this.topCompetenceDashboard = data.topCompetenceCount as TopCompetenceDashboard[];
            this.topBudgetDashboard = data.topBudgetCount as TopBudgetDashboard[];
            this.setTopCharts();
        });
    }

    setTopCharts() {
        const a: any = [];
        const b: any = [];
        const c: any = [];
        const d: any = [];
        this.topConsultDashboard.map((x, i) => {
            const arr: any = [x.name];
            for (let n = 0; n <= this.topConsultDashboard.length - 1; n++) {
                if (i === n) {
                    arr.push(parseInt(x.count, 0));
                } else {
                    arr.push(0);
                }
            }
            a.push(arr);
        });
        this.topSuggestionDashboard.map((x, i) => {
            const arr: any = [x.name];
            for (let n = 0; n <= this.topSuggestionDashboard.length - 1; n++) {
                if (i === n) {
                    arr.push(parseInt(x.count, 0));
                } else {
                    arr.push(0);
                }
            }
            b.push(arr);
        });
        this.topCompetenceDashboard.map((x, i) => {
            const arr: any = [x.name];
            for (let n = 0; n <= this.topCompetenceDashboard.length - 1; n++) {
                if (i === n) {
                    arr.push(parseInt(x.count, 0));
                } else {
                    arr.push(0);
                }
            }
            c.push(arr);
        });
        this.topBudgetDashboard.map((x, i) => {
            const arr: any = [x.name];
            for (let n = 0; n <= this.topBudgetDashboard.length - 1; n++) {
                if (i === n) {
                    arr.push(parseInt(x.count, 0));
                } else {
                    arr.push(0);
                }
            }
            d.push(arr);
        });
        this.chartDataBarPrimary.data = a;
        this.chartDataBarSecondary.data = b;
        this.chartDataBarTertiary.data = c;
        this.chartDataBarQuartenary.data = d;
    }

    getCards() {
        this.cardsQuery = this.cardGQL.watch({input: this.statisticsFilter});
        this.cardsQuery.valueChanges.subscribe(({data}) => {
            this.cardConsultDashboard = data.cardConsultCount as CardConsultDashboard;
            this.cardSuggestionDashboard = data.cardSuggestionCount as CardSuggestionDashboard;
            this.cardCompetenceDashboard = data.cardCompetenceCount as CardCompetenceDashboard;
            this.cardBudgetDashboard = data.cardBudgetCount as CardBudgetDashboard;
            this.likesChartConsult.data = [
                ['Positivo', parseInt(this.cardConsultDashboard.positive, 0), 0],
                ['Negativo', 0, parseInt(this.cardConsultDashboard.negative, 0)]
            ];
            this.likesChartSuggestion.data = [
                ['Positivo', parseInt(this.cardSuggestionDashboard.positive, 0), 0],
                ['Negativo', 0, parseInt(this.cardSuggestionDashboard.negative, 0)]
            ];
        });
    }

    getProducts() {
        this.productsQuery = this.getProductsGQL.watch({});
        this.productsQuery.valueChanges.subscribe(({data}) => {
            this.product = data.products as Array<Product>;
        });
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        const start = moment(this.statisticsFilter.start).format('YYYY-MM-DD');
        const end = moment(this.statisticsFilter.end).format('YYYY-MM-DD');
        let url = `${environment.base_url}/dashboards/search-dashboard/excel?token=${token}`;

        url += ('&language=' + (this.translate.currentLang === 'pt' ? 'pt-BR' : 'es-ES'));

        if (!super.isNullOrUndefined(start)) {
            url += ('&start=' + start);
        }
        if (!super.isNullOrUndefined(end)) {
            url += ('&end=' + end);
        }

        if (!super.isNullOrUndefined(this.filterCountries)) {
            url += ('&countries=');
            this.filterCountries.forEach((e, index) => {
                url += ((index !== 0 ? '|' : '') + e.id);
            });
        }

        if (!super.isNullOrUndefined(this.statisticsFilter.line)) {
            url += ('&line=' + this.statisticsFilter.line);
        }
        if (!super.isNullOrUndefined(this.statisticsFilter.product)) {
            url += ('&product=' + this.statisticsFilter.product);
        }
        window.open(encodeURI(url));
    }


    /*  ngAfterViewInit() {
          this.actionDashBoard();
          this.userService.user.subscribe({
              next: data => {
                  if (!this.isNullOrUndefined(data.id)) {
                      this.btnNew = data.profile === 'local' || data.profile === 'administrator';
                  }
              }
          });
      }

      actionDashBoard() {
          // this.loading.show();
          // this.dashboardService.get().subscribe({
          //     next: data => {
          //         this.modelDashboard = data as Dashboard;
          //         this.makeData();
          //         this.makeDataTertiary();
          //         this.makeDataSecondary();
          //         this.updateQuarterChartCategories('secondaryChart', this.makeDataSecondaryCategories());
          //         this.loading.hide();
          //     }, error: err => super.onError(err)
          // });
      }


      makeData() {
          const labels = [];
          const series = [];
          // tslint:disable-next-line:forin
          for (const array in this.modelDashboard.active) {
              labels.push(this.modelDashboard.active[array].step);
              series.push(this.modelDashboard.active[array].value);
          }
          this.chartOptions.labels = labels;
          this.chartOptions.series = series;
      }

      makeDataTertiary() {
          let arr = [];
          const open = [];
          const finish = [];

          // tslint:disable-next-line:forin
          for (const array in this.modelDashboard.opened) {
              open.push(this.modelDashboard.opened[array].open);
              finish.push(this.modelDashboard.opened[array].finish);
              this.categoriesCustom.push(this.modelDashboard.opened[array].step);
          }
          arr = [
              {
                  name: 'Finalizados',
                  data: finish,
                  color: '#1b2e5b'
              },
              {
                  name: 'Em aberto',
                  data: open,
                  color: '#fae100'
              },
          ];
          this.chartOptionsTertiary.series = arr;
      }

      makeDataSecondary() {
          const arr = [];
          const arr2 = [
              {
                  name: 'AR Local',
                  data: [45, 52, 38, 24, 33],
                  color: '#1b2e5b',
              },
              {
                  name: 'AR Internacional',
                  data: [35, 41, 62, 42, 13],
                  color: '#fae100',
              },
              {
                  name: 'Artes',
                  data: [87, 57, 74, 99, 75],
                  color: '#656f88',
              },
              {
                  name: 'Cadastro',
                  data: [20, 12, 23, 55, 17],
                  color: '#00358e'
              }];

          const colorCustom = [
              '#1b2e5b',
              '#fae100',
              '#656f88',
              '#00358e',
          ];

          // tslint:disable-next-line:forin
          for (const array in this.modelDashboard.months.values) {
              let arrValues = [];

              // tslint:disable-next-line:forin
              for (const arrayValues in this.modelDashboard.months.values) {
                  arrValues = Object.assign([], this.modelDashboard.months.values[array].count);
              }

              arr.push({
                  name: this.modelDashboard.months.values[array].step,
                  data: arrValues,
                  color: colorCustom[array],
              });
          }
          return arr;
      }

      makeDataSecondaryCategories() {
          const arr = this.modelDashboard.months.month;
          return arr;
      }

      makeDataSecondaryDataArrays() {
          const arr = [];
          // tslint:disable-next-line:forin
          for (const array in this.modelDashboard.months.month) {
              arr.push(0);
          }
          return arr;
      }

      actionUpdateGraph() {
          this.flagResultMoc = !this.flagResultMoc;
          this.updateQuarterChart('primaryChart', this.makeData());
          this.updateQuarterChart('secondaryChart', this.makeDataSecondary());
          this.updateQuarterChart('tertiaryChart', this.makeDataTertiary());
      }

      updateQuarterChart(destChartIDToUpdate, func) {
          return window.ApexCharts.exec(destChartIDToUpdate, 'updateOptions', {
              series: func
          });
      }

      updateQuarterChartCategories(destChartIDToUpdate, func) {
          return window.ApexCharts.exec(destChartIDToUpdate, 'updateOptions', {
              series: this.makeDataSecondary(),
              xaxis: {categories: this.makeDataSecondaryCategories()}
          });
      }
  */

}



