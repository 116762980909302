<div class="body">
    <div class="header">
        <h1 class="header-title ">
            <svg-icon class="mr-3" src="assets/svg/tiles.svg"></svg-icon>
            Dashboard
        </h1>
        <a href="javascript:void(0)"
           class="btn btn-gray"
           (click)="getExcel()">
            <svg-icon src="assets/svg/download.svg"></svg-icon>
            <span>{{ 'exportarexcel' | translate }}</span>
        </a>
    </div>
    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">

        <!--PRODUCTO-->
        <div class="input-material flex-grow-1">
            <input id="start" class="form-control" type="text"
                   [(ngModel)]="this.statisticsFilterFront.start" mask="d0/M0/0000" [dropSpecialCharacters]="false"
                   required/>
            <label for="start">{{ 'start' | translate }}</label>
        </div>

        <div class="input-material flex-grow-1">
            <input id="end" class="form-control" type="text"
                   [(ngModel)]="this.statisticsFilterFront.end" mask="d0/M0/0000" [dropSpecialCharacters]="false"
                   required/>
            <label for="end">{{ 'end' | translate }}</label>
        </div>

        <!--COUNTRY-->
        <div class="input-material flex-grow-1">
            <ng-multiselect-dropdown
                    [settings]="dropSettings"
                    [(ngModel)]="filterCountries"
                    [data]="countriesUser"
                    [placeholder]="'buscar' | translate"
                    (ngModelChange)="getLinesByCountries();"
            >
            </ng-multiselect-dropdown>
            <label class="mb-0 mr-3 disabled">{{ 'pais' | translate }}</label>
        </div>

        <!-- LINE -->
        <div class="input-material flex-grow-1">
            <select id="linear" class="form-control" name="type" [(ngModel)]="statisticsFilter.line"
                    (ngModelChange)="getProductsByLine()"
            >
                <option selected
                        [ngValue]="undefined">{{ 'minhalista.input3.subtitle' | translate }}
                </option>
                <option [value]="l.id" *ngFor="let l of lines">{{ l.name }}</option>
            </select>
            <label for="linear" class="mb-0 mr-3">{{ 'linha'|translate }}</label>
        </div>

        <div class="input-material flex-grow-1">
            <select id="status" class="form-control" name="type" [(ngModel)]="statisticsFilter.product">
                <option selected
                        [ngValue]="undefined">{{ 'minhalista.input3.subtitle' | translate }}
                </option>
                <option [value]="p.id" *ngFor="let p of products">{{ p.name }}</option>
            </select>
            <label for="status" class="mb-0 mr-3">{{ 'produto'|translate }}</label>
        </div>


        <a href="javascript:void(0)"
           class="btn btn-primary flex-grow-1" (click)="onFilter();"
           type="button">
            <span>{{ 'filtrar'| translate }}</span>
        </a>
    </div>

    <div class="row justify-content-around flex-column flex-md-row">
        <div class="col-md-3 col-12">
            <div class="card_dash">
                <div class="d-flex justify-content-between w-100 align-items-center">
                    <svg-icon src="assets/svg/question.svg"></svg-icon>
                    <b>{{ !statistics.consult ? 0 : statistics.consult }}</b>
                </div>
                <span>{{ 'consultamedica' | translate }}</span>
            </div>
        </div>
        <div class="col-md-3 col-12">
            <div class="card_dash">
                <div class="d-flex justify-content-between w-100 align-items-center">
                    <svg-icon src="assets/svg/suggest.svg"></svg-icon>
                    <b>{{ !statistics.suggestion ? 0 : statistics.suggestion }}</b>
                </div>
                <span>{{ 'sugerencias' | translate }}</span>
            </div>
        </div>
        <div class="col-md-3 col-12">
            <div class="card_dash">
                <div class="d-flex justify-content-between w-100 align-items-center">
                    <svg-icon src="assets/svg/competence.svg"></svg-icon>
                    <b>{{ !statistics.competence ? 0 : statistics.competence }}</b>
                </div>
                <span>{{ 'menu.competence' | translate }}</span>
            </div>
        </div>
        <div class="col-md-3 col-12">
            <div class="card_dash">
                <div class="d-flex justify-content-between w-100 align-items-center">
                    <svg-icon src="assets/svg/product.svg"></svg-icon>
                    <b>{{ !statistics.budget ? 0 : statistics.budget }}</b>
                </div>
                <span>{{ 'menu.budget' | translate }}</span>
            </div>
        </div>
    </div>

    <div class="my-4">
        <hr/>
    </div>

    <ng-container *ngIf="user.profile === 'admin' && userCountrySpecific">
        <div class="d-flex justify-content-between flex-column flex-xl-row">
            <div class="card_bg radius-24 col-12">
                <div class="col-12 d-flex justify-content-between align-items-center mb-4 flex-wrap">
                    <h4 class="mb-xl-0 mb-md-0 mb-sm-0 mb-3">Ranking</h4>
                    <div class="col-12 col-xl-5 col-md-6 col-sm-8 d-flex justify-content-between">
                        <a href="javascript:void(0)"
                           class="btn btn-primary mr-4 d-flex align-items-center"
                           (click)="getExcel()">
                            <svg-icon class="svg-white mr-1" src="assets/svg/download.svg"></svg-icon>
                            <span>{{ 'exportarexcel' | translate }}</span>
                        </a>
                        <div class="flex-grow-1">
                            <div class="input-material flex-grow-1">
                                <select id="quarter" class="form-control" [(ngModel)]="selectedQuarter"
                                        (ngModelChange)="getRankingByCountry(selectedQuarter);">
                                    <option [ngValue]="undefined">{{ 'minhalista.input3.subtitle' | translate }}</option>
                                    <option [value]="item.quarter"
                                            *ngFor="let item of listQuartersArr">{{ item.quarter }}
                                    </option>
                                </select>
                                <label for="quarter" class="mb-0 mr-3">{{ 'trimestre' | translate }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 d-flex align-items-center justify-content-center"
                         *ngIf="isNullOrUndefined(rankingByCountryArr)">
                        <p>Nenhum resultado encontrado.</p>
                    </div>
                    <ng-container *ngFor="let item of rankingByCountryArr">
                        <div class="col-12 col-md-6 col-xl-4">
                            <div class="card_graph">
                                <div class="top d-flex align-items-center justify-content-between">
                                    <b>{{ item.productLine }}</b>
                                    <b>{{ item.percent }}%</b>
                                </div>
                                <ng-container *ngFor="let itemRanking of item.ranking">
                                    <div class="w-100 d-flex justify-content-between align-items-center py-3 line-row">
                                        <div class="col-9 d-flex align-items-center">
                                            <b class="mb-0 mr-3">{{ itemRanking.position }}º</b>
                                            <span>{{ itemRanking.name }}</span>
                                        </div>
                                        <div class="col-3 d-flex justify-content-end align-items-center">
                                            <span>{{ itemRanking.totalScore }}</span>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="w-100 mt-3">
                                    <div class="card_dash bg-white">
                                        <div class="d-flex justify-content-between w-100 align-items-center">
                                            <svg-icon src="assets/svg/suggest.svg"></svg-icon>
                                            <b>{{ !isNullOrUndefined(item.totalAnswered) ? item.totalAnswered : 0 }}</b>
                                        </div>
                                        <span>{{ 'totalDeSugestoes' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <!--        <ng-container *ngIf="user.profile === 'admin'">-->
            <!--            <div class="card_bg radius-24 bg-cyan col-12 col-xl-4">-->
            <!--                <h4 class="pb-4">Ranking</h4>-->
            <!--                <div class="col">-->
            <!--                    <div class="row mb-4 justify-content-between">-->
            <!--                        &lt;!&ndash;                    LINHA&ndash;&gt;-->
            <!--                        <div class="col-12 col-md-8">-->
            <!--                            <div class="input-material flex-grow-1">-->
            <!--                                <select id="line" class="form-control" [(ngModel)]="lineRankingModel"-->
            <!--                                        (ngModelChange)="getRanking(lineRankingModel, selectedQuarter); getUnScoredTotal(lineRankingModel, selectedQuarter)">-->
            <!--                                    <option [ngValue]="undefined">{{ 'minhalista.input3.subtitle' | translate }}</option>-->
            <!--                                    <option [value]="item.id" *ngFor="let item of productLinesArr">{{ item.name }}-->
            <!--                                    </option>-->
            <!--                                </select>-->
            <!--                                <label for="line" class="mb-0 mr-3">{{ 'linha' | translate }}</label>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        &lt;!&ndash;                1 TRI&ndash;&gt;-->

            <!--                    </div>-->

            <!--                    <div class="card_graph overflow-auto">-->
            <!--                        <div class="top">-->
            <!--                            <b>{{ 'maisVotados' | translate }}</b>-->
            <!--                        </div>-->
            <!--                        <ng-container *ngIf="isNullOrUndefined(rankingArr)">-->
            <!--                            <p class="text-center text-primary p-0 my-3">Nenhum resultado encontrado.</p>-->
            <!--                        </ng-container>-->
            <!--                        <ng-container *ngFor="let item of rankingArr; let i = index">-->
            <!--                            <div class="w-100 d-flex justify-content-between align-items-center py-3 line-row">-->
            <!--                                <div class="col-9 d-flex align-items-center">-->
            <!--                                    <b class="mb-0 mr-3">{{ item.position }}º</b>-->
            <!--                                    <span>{{ item.name }}</span>-->
            <!--                                </div>-->
            <!--                                <div class="col-3 d-flex justify-content-end align-items-center">-->
            <!--                                    <span>{{ item.totalScore }}</span>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </ng-container>-->
            <!--                    </div>-->

            <!--                    <div class="w-100">-->
            <!--                        <div class="card_dash bg-white">-->
            <!--                            <div class="d-flex justify-content-between w-100 align-items-center">-->
            <!--                                <svg-icon src="assets/svg/suggest.svg"></svg-icon>-->
            <!--                                <b>{{ !isNullOrUndefined(getUnScoredTotalDto.total) ? getUnScoredTotalDto.total : 0 }}</b>-->
            <!--                            </div>-->
            <!--                            <span>{{ 'totalDeSugestoes' | translate }}</span>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--        </ng-container>-->
        </div>
    </ng-container>

    <div class="d-flex justify-content-between flex-column flex-xl-row">
        <div class="card_bg radius-24 col-12">
            <h4 class="mb-4">{{ 'topprodutos'|translate }}</h4>
            <div class="row">
                <div class="col-12 col-md-6 col-xl-6">
                    <div class="card_graph">
                        <div class="top">
                            <b>{{ 'consultamedica' | translate }}</b>
                        </div>
                        <google-chart style="width: 100%;"
                                      [height]="chartDataBarPrimary.height"
                                      [type]="chartDataBarPrimary.type"
                                      [data]="chartDataBarPrimary.data"
                                      [options]="chartDataBarPrimary.options">
                        </google-chart>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-xl-6">
                    <div class="card_graph">
                        <div class="top">
                            <b>{{ 'sugerencias' | translate }}</b>
                        </div>
                        <google-chart style="width: 100%;"
                                      [height]="chartDataBarSecondary.height"
                                      [type]="chartDataBarSecondary.type"
                                      [data]="chartDataBarSecondary.data"
                                      [options]="chartDataBarSecondary.options">
                        </google-chart>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-xl-6">
                    <div class="card_graph">
                        <div class="top">
                            <b>{{ 'menu.competence' | translate }}</b>
                        </div>
                        <google-chart style="width: 100%;"
                                      [height]="chartDataBarTertiary.height"
                                      [type]="chartDataBarTertiary.type"
                                      [data]="chartDataBarTertiary.data"
                                      [options]="chartDataBarTertiary.options">
                        </google-chart>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-xl-6">
                    <div class="card_graph">
                        <div class="top">
                            <b>{{ 'menu.budget' | translate }}</b>
                        </div>
                        <google-chart style="width: 100%;"
                                      [height]="chartDataBarQuartenary.height"
                                      [type]="chartDataBarQuartenary.type"
                                      [data]="chartDataBarQuartenary.data"
                                      [options]="chartDataBarQuartenary.options">
                        </google-chart>
                    </div>
                </div>
            </div>
        </div>

        <!--        <ng-container *ngIf="user.profile === 'admin'">-->
        <!--            <div class="card_bg radius-24 bg-cyan col-12 col-xl-4">-->
        <!--                <h4 class="pb-4">Ranking</h4>-->
        <!--                <div class="col">-->
        <!--                    <div class="row mb-4 justify-content-between">-->
        <!--                        &lt;!&ndash;                    LINHA&ndash;&gt;-->
        <!--                        <div class="col-12 col-md-8">-->
        <!--                            <div class="input-material flex-grow-1">-->
        <!--                                <select id="line" class="form-control" [(ngModel)]="lineRankingModel" (ngModelChange)="getRanking(lineRankingModel, selectedQuarter); getUnScoredTotal(lineRankingModel, selectedQuarter)">-->
        <!--                                    <option [ngValue]="undefined">{{ 'minhalista.input3.subtitle' | translate }}</option>-->
        <!--                                    <option [value]="item.id" *ngFor="let item of productLinesArr">{{ item.name }}</option>-->
        <!--                                </select>-->
        <!--                                <label for="line" class="mb-0 mr-3">{{ 'linha' | translate }}</label>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        &lt;!&ndash;                1 TRI&ndash;&gt;-->
        <!--                        <div class="col-12 col-md-4">-->
        <!--                            <div class="input-material flex-grow-1">-->
        <!--                                <select id="quarter" class="form-control" [(ngModel)]="selectedQuarter" (ngModelChange)="getRanking(lineRankingModel, selectedQuarter); getUnScoredTotal(lineRankingModel, selectedQuarter)">-->
        <!--                                    <option [ngValue]="undefined">{{ 'minhalista.input3.subtitle' | translate }}</option>-->
        <!--                                    <option [value]="item.quarter" *ngFor="let item of listQuartersArr">{{ item.quarter }}</option>-->
        <!--                                </select>-->
        <!--                                <label for="quarter" class="mb-0 mr-3">{{ 'trimestre' | translate }}</label>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->

        <!--                    <div class="card_graph overflow-auto">-->
        <!--                        <div class="top">-->
        <!--                            <b>{{ 'maisVotados' | translate }}</b>-->
        <!--                        </div>-->
        <!--                        <ng-container *ngIf="isNullOrUndefined(rankingArr)">-->
        <!--                            <p class="text-center text-primary p-0 my-3">Nenhum resultado encontrado.</p>-->
        <!--                        </ng-container>-->
        <!--                        <ng-container *ngFor="let item of rankingArr; let i = index">-->
        <!--                            <div class="w-100 d-flex justify-content-between align-items-center py-3 line-row">-->
        <!--                                <div class="col-9 d-flex align-items-center">-->
        <!--                                    <b class="mb-0 mr-3">{{ item.position }}º</b>-->
        <!--                                    <span>{{ item.name }}</span>-->
        <!--                                </div>-->
        <!--                                <div class="col-3 d-flex justify-content-end align-items-center">-->
        <!--                                    <span>{{ item.totalScore }}</span>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </ng-container>-->
        <!--                    </div>-->

        <!--                    <div class="w-100">-->
        <!--                        <div class="card_dash bg-white">-->
        <!--                            <div class="d-flex justify-content-between w-100 align-items-center">-->
        <!--                                <svg-icon src="assets/svg/suggest.svg"></svg-icon>-->
        <!--                                <b>{{ !isNullOrUndefined(getUnScoredTotalDto.total) ? getUnScoredTotalDto.total : 0 }}</b>-->
        <!--                            </div>-->
        <!--                            <span>{{ 'totalDeSugestoes' | translate }}</span>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </ng-container>-->
    </div>

    <div class="row justify-content-around">
        <div class="col-md-6">
            <div class="card">
                <div class="top">
                    <svg-icon class="mr-3" src="assets/svg/question.svg"></svg-icon>
                    <b>{{ 'consultamedica' | translate }}</b>
                </div>
                <div class="content">
                    <div class="row align-items-center justify-content-around">
                        <div class="col-md-2 px-0 text-nowrap">
                            <b>Status</b>
                        </div>
                        <div class="col-md-4">
                            <div class="card_content primary">
                                <b>{{ cardConsultDashboard.pending }}</b>
                                <span>{{ 'open' | translate }}</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card_content secondary">
                                <b>{{ cardConsultDashboard.answered }}</b>
                                <span>{{ 'minhalista.concluido' | translate }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row align-items-center justify-content-around">
                        <div class="col-md-2 px-0 text-nowrap">
                            <b>Likes</b>
                        </div>
                        <div class="col-md-8">
                            <!--{{cardConsultDashboard.positive}} - {{cardConsultDashboard.negative}}-->
                            <google-chart style="width: 100%;"
                                          [height]="likesChartConsult.height"
                                          [type]="likesChartConsult.type"
                                          [data]="likesChartConsult.data"
                                          [options]="likesChartConsult.options">
                            </google-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="card">
                <div class="top">
                    <svg-icon class="mr-3" src="assets/svg/suggest.svg"></svg-icon>
                    <b>{{ 'sugerencias' | translate }}</b>
                </div>
                <div class="content">
                    <div class="row align-items-center justify-content-around">
                        <div class="col-md-2 px-0 text-nowrap">
                            <b>Status</b>
                        </div>
                        <div class="col-md-4">
                            <div class="card_content primary">
                                <b>{{ cardSuggestionDashboard.pending }}</b>
                                <span>{{ 'open' | translate }}</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card_content secondary">
                                <b>{{ cardSuggestionDashboard.answered }}</b>
                                <span>{{ 'minhalista.concluido' | translate }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row align-items-center justify-content-around">
                        <div class="col-md-2 px-0 text-nowrap">
                            <b>Likes</b>
                        </div>
                        <div class="col-md-8">
                            <!--{{cardSuggestionDashboard.positive}} - {{cardSuggestionDashboard.negative}}-->
                            <google-chart style="width: 100%;"
                                          [height]="likesChartSuggestion.height"
                                          [type]="likesChartSuggestion.type"
                                          [data]="likesChartSuggestion.data"
                                          [options]="likesChartSuggestion.options">
                            </google-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="card">
                <div class="top">
                    <svg-icon class="mr-3" src="assets/svg/competence.svg"></svg-icon>
                    <b>{{ 'menu.competence' | translate }}</b>
                </div>
                <div class="content">
                    <div class="row align-items-center justify-content-around">
                        <div class="col-md-2 px-0 text-nowrap">
                            <b>Status</b>
                        </div>
                        <div class="col-md-4">
                            <div class="card_content primary">
                                <b>{{ cardCompetenceDashboard.pending }}</b>
                                <span>{{ 'open' | translate }}</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card_content secondary">
                                <b>{{ cardCompetenceDashboard.answered }}</b>
                                <span>{{ 'minhalista.concluido' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <!--<div class="row align-items-center justify-content-around">
                        <div class="col-md-2">
                            <b>Likes</b>
                        </div>
                        <div class="col-md-8">
                            <img src="assets/images/graph.png" alt="">
                        </div>
                    </div>-->
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="card">
                <div class="top">
                    <svg-icon class="mr-3" src="assets/svg/product.svg"></svg-icon>
                    <b>{{ 'menu.budget' | translate }}</b>
                </div>
                <div class="content">
                    <div class="row align-items-center justify-content-around">
                        <div class="col-md-2 px-0 text-nowrap">
                            <b>Status</b>
                        </div>
                        <div class="col-md-10 d-flex flex-wrap">
                            <div class="card_content flex-grow-1 mx-1 approved">
                                <b>{{ cardBudgetDashboard.approved }}</b>
                                <span>{{ 'approved-status' | translate }}</span>
                            </div>
                            <div class="card_content flex-grow-1 mx-1 review">
                                <b>{{ cardBudgetDashboard.pending }}</b>
                                <span>{{ 'pendente'| translate }}</span>
                            </div>
                            <div class="card_content flex-grow-1 mx-1 secondary">
                                <b>{{ cardBudgetDashboard.revision }}</b>
                                <span>{{ 'inRevision-status'| translate }}</span>
                            </div>
                            <div class="card_content flex-grow-1 mx-1 disapproved">
                                <b>{{ cardBudgetDashboard.disapproved }}</b>
                                <span> {{ 'disapproved-status' | translate }}</span>
                            </div>
                        </div>
                        <!--<div class="col-md-2">
                            <div class="card_content approved">
                                <b>{{cardBudgetDashboard.approved}}</b>
                                <span>Aprovado</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="card_content review">
                                <b>{{cardBudgetDashboard.pending}}</b>
                                <span>Pendente</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="card_content secondary">
                                <b>{{cardBudgetDashboard.revision}}</b>
                                <span>Em revisão</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="card_content disapproved">
                                <b>{{cardBudgetDashboard.disapproved}}</b>
                                <span>Reprovado</span>
                            </div>
                        </div>-->
                    </div>
                    <!--<div class="row align-items-center justify-content-around">
                        <div class="col-md-2">
                            <b>Likes</b>
                        </div>
                        <div class="col-md-8">
                            <img src="assets/images/graph.png" alt="">
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>

</div>


