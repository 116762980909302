<div class="body">
    <div class="header">
        <h1 class="header-title">
            <svg-icon src="assets/svg/{{this.icon}}.svg"></svg-icon>
            {{'competencia'| translate}}
        </h1>
        <a href="javascript:void(0)"
           class="btn btn-gray"
           (click)="getExcel()">
            <svg-icon src="assets/svg/download.svg"></svg-icon>
            <span> {{'exportarexcel'| translate}}</span>
        </a>
    </div>
    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
        <!--KEYWORD-->
        <div class="input-material" style="flex-grow: 9">
            <input id="search" [(ngModel)]="filter.keyword" class="form-control" type="text" required/>
            <label for="search">
                <svg-icon src="assets/svg/search.svg"></svg-icon>
                {{'busca'| translate}}
            </label>
        </div>
        <div class="divider d-none d-md-block">&nbsp;</div>
        <!--COUNTRY-->
        <div class="input-material flex-grow-1">
            <ng-multiselect-dropdown
                    [settings]="dropSettings"
                    [(ngModel)]="filterCountries"
                    [data]="countriesUser"
                    [placeholder]="'buscar' | translate"
                    (ngModelChange)="getLinesByCountries();"
            >
            </ng-multiselect-dropdown>
            <label class="mb-0 mr-3 disabled">{{'pais' | translate}}</label>
        </div>
        <!--LINE-->
        <div class="input-material flex-grow-1">
            <select id="primary" class="form-control" name="type" [(ngModel)]="filter.line"
                    (ngModelChange)="getProductsByLine()"
            >
                <option selected
                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                <option [value]="l.id" *ngFor="let l of lines">{{l.name}}</option>
            </select>
            <label for="primary" class="mb-0 mr-3"> {{'linha'| translate}}</label>
        </div>
        <!--PRODUCTO-->
        <div class="input-material flex-grow-1">
            <select id="producto" class="form-control" name="type" [(ngModel)]="filter.product">
                <option selected
                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                <option [value]="p.id" *ngFor="let p of products">{{p.name}}</option>
            </select>
            <label for="producto" class="mb-0 mr-3">{{'produto'| translate}}</label>
        </div>
        <!--STATUS-->
        <div class="input-material flex-grow-1">
            <select id="status" class="form-control" name="type" [(ngModel)]="filter.status">
                <option selected
                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                <option value="pending">{{translate.instant('statusNames.pending')}}</option>
                <option value="answered">{{translate.instant('statusNames.answered')}}</option>
            </select>
            <label for="status" class="mb-0 mr-3">Status</label>
        </div>
        <!--PERIOD-->
        <div class="input-material flex-grow-1">
            <input id="start" class="form-control" type="text"
                   [(ngModel)]="this.filterFront.start" mask="d0/M0/0000" [dropSpecialCharacters]="false" required/>
            <label for="start">{{'start' | translate}}</label>
        </div>

        <div class="input-material flex-grow-1">
            <input id="end" class="form-control" type="text"
                   [(ngModel)]="this.filterFront.end" mask="d0/M0/0000" [dropSpecialCharacters]="false" required/>
            <label for="end">{{'end' | translate}}</label>
        </div>


        <a href="javascript:void(0)"
           (click)="onFilter()"
           class="btn btn-gray flex-grow-1"
           type="button">
            <span>{{'filtrar'| translate}}</span>
        </a>
    </div>
    <!--GRID-->
    <div class="table-responsive">
        <table class="table">
            <thead>
            <tr>
                <th scope="col">{{'linha'| translate}}</th>
                <th scope="col">{{'produto'| translate}}</th>
                <th scope="col">{{'usuario'| translate}}</th>
                <th scope="col">{{'datacadastro'| translate}}</th>
                <th ><p class="text-center m-0">{{'pais' | translate}}</p></th>
                <th scope="col">Status</th>
                <th class="text-center" scope="col">{{'score'|translate}}</th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isNullOrUndefined(result)">
                <td colspan="6" class="text-center py-5">{{'nenhumresultado'| translate}}</td>
            </tr>
            <!--
            <tr *ngFor="let x of result | paginate: {
                        itemsPerPage: modelResult.pageSize,
                        currentPage: modelResult.currentPage,
                        totalItems: modelResult.totalCount}">
            -->
            <tr *ngFor="let x of result">
                <td>
                    <div class="d-flex align-items-center" style="gap: 18px">
                        <div style="width: 4px; height: 42px;" [ngClass]="{
                                'bg-warning' : (x.status === 'pending'),
                                'bg-success' : (x.status === 'answered')
                            }"></div>
                        {{x.product?.productLine?.name}}
                    </div>
                </td>
                <td>
                    <div class="d-flex">
                        {{x.product?.name}}
                    </div>
                </td>
                <td>{{x.createdBy?.name}}</td>
                <td>{{x.createdAt | date: 'dd/MM/yyyy'}}</td>
                <td class="d-flex flex-column align-items-center justify-content-center">
                    <img src="{{x.country?.flag}}" width="25">{{x.country.name}}
                </td>
                <td>{{getStatusNameByKey(x.status)}}</td>
                <td class="text-center">{{x.score}}</td>
                <td>
                    <button class="mr-4" [routerLink]="'/competence/' + x.id">
                        <svg-icon src="assets/svg/opened.svg" *ngIf="x.status === 'pending'"></svg-icon>
                        <svg-icon src="assets/svg/external.svg" *ngIf="x.status !== 'pending'"></svg-icon>
                    </button>
                    <button (click)="actionDelete(x.id)" *ngIf=actionUserProfile()>
                        <svg-icon src="assets/svg/delete.svg"></svg-icon>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <!--<div>
        <pagination-controls
            [previousLabel]="labels.previousLabel"
            [nextLabel]="labels.nextLabel"
            [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
            [screenReaderPageLabel]="labels.screenReaderPageLabel"
            [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
            (pageChange)="pageChanged($event)"></pagination-controls>
    </div>-->
</div>

