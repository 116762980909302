import {Component, OnInit} from '@angular/core';
import {QueryRef} from 'apollo-angular';
import {
    Budget,
    CitiesQuery,
    Competence,
    Product,
    ProductsByLinesGQL,
    ProductsByLinesQuery,
    SearchBudgetAll,
    SearchBudgetsQuery,
    Suggestion,
    SearchSuggestionAll,
    SearchCompetenceAllQuery,
    SearchCompetenceAllGQL,
    GetProductLinesGQL,
    GetProductLinesQuery,
    SuggestionfilterInput,
    SearchSuggestionGQL,
    SearchSuggestionQuery,
    SuggestionResponseDto,
    ProductLine,
    Country,
    GetCountriesQuery,
    GetCountriesGQL,
    User,
    RemoveCompetenceGQL,
    RemoveSuggestionGQL,
    GetCountriesByUserQuery,
    GetCountriesByUserGQL,
    SearchProductLine,
    SearchProductLinesByCountryGQL,
    SearchProductLinesByCountryQuery,
    GetProductsByLineQuery,
    GetProductsByLineGQL,
} from '../../../generated/graphql';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {UserService} from '../../service/user.service';
import moment from 'moment';

@Component({
    selector: 'app-suggestions',
    templateUrl: './suggestions.component.html',
    styleUrls: ['./suggestions.component.scss']
})
export class SuggestionsComponent extends BaseComponent implements OnInit {

    user = new User();
    searchResultQuery: QueryRef<SearchSuggestionQuery>;
    filterFront = new SearchSuggestionAll();
    filter = new SearchSuggestionAll();
    result: Array<SuggestionResponseDto>;
    // lineQuery: QueryRef<GetProductLinesQuery>;
    // line: Array<ProductLine>;

    lines: Array<ProductLine> = [];
    linesQuery: QueryRef<SearchProductLinesByCountryQuery>;

    productsByLineQuery: QueryRef<GetProductsByLineQuery>;
    products: Array<Product> = [];

    productQuery: QueryRef<ProductsByLinesQuery>;
    product: Array<Product>;

    countries: Array<Country>;
    countryQuery: QueryRef<GetCountriesQuery>;

    countriesUser: Array<Country>;
    countryUserQuery: QueryRef<GetCountriesByUserQuery>;

    filterCountries: Country[] = [];
    filterL = new SearchProductLine();

    dropSettings: any = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Marcar todos',
        unSelectAllText: 'Desmarcar todos',
        itemsShowLimit: 3,
        allowSearchFilter: true
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public searchCompetenceAllGQL: SearchCompetenceAllGQL,
                private productsByLinesGQL: ProductsByLinesGQL,
                private getProductLinesGQL: GetProductLinesGQL,
                private getProductsByLineGQL: GetProductsByLineGQL,
                private searchSuggestionGQL: SearchSuggestionGQL,
                public searchProductLinesByCountryGQL: SearchProductLinesByCountryGQL,
                public getCountriesGQL: GetCountriesGQL,
                public getCountriesByUserGQL: GetCountriesByUserGQL,
                private removeSuggestion: RemoveSuggestionGQL,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, loading, translate);
        this.filterFront.start = moment().startOf('day').subtract(30, 'days').utcOffset(0, true).format('DD/MM/YYYY');
        this.filterFront.end = moment().endOf('day').utcOffset(0, true).format('DD/MM/YYYY');
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                if (!this.isNullOrUndefined(this.user.id)) {
                    // this.getCountries();
                    // this.getLines();
                    // this.getProducts();
                    this.setFilter();
                    this.getCountriesByUser();
                    this.searchResultQuery = this.searchSuggestionGQL.watch({
                        filter: this.filter
                    });
                    this.searchResultQuery.valueChanges.subscribe(({data}) => {
                        this.result = data.searchSuggestionsAll as Array<SuggestionResponseDto>;
                    });
                }
            }, error: err => super.onError(err)
        });
    }

    setFilter() {
        this.filter.start = moment(this.filterFront.start, 'DD/MM/YYYY').startOf('day').format();
        this.filter.end = moment(this.filterFront.end, 'DD/MM/YYYY').endOf('day').format();
    }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            this.countries = data.countries as Array<Country>;
        });
    }

    getCountriesByUser() {
        this.countryUserQuery = this.getCountriesByUserGQL.watch();
        this.countryUserQuery.valueChanges.subscribe(({data}) => {
            this.countriesUser = data.countriesByUser as Array<Country>;
        });
    }

    getLinesByCountries() {
        this.filterL.countries = this.filterCountries.map(e => e.id);
        this.linesQuery = this.searchProductLinesByCountryGQL.watch({id: this.filterL.countries});
        this.linesQuery.valueChanges.subscribe(({data}) => {
            this.lines = data.searchProductLinesByCountry as Array<ProductLine>;
        });
    }

    getProductsByLine() {
        this.productsByLineQuery = this.getProductsByLineGQL.watch({
            lines: this.filter.line
        });
        this.productsByLineQuery.valueChanges.subscribe(({data}) => {
            this.products = data.productsByLines as Array<Product>;
        });
        // .pipe(map(result => result.data.productsByLines as Array<Product>));
    }

    // getLines() {
    //     this.filter.countries = this.filterCountries.map(e => e.id);
    //     this.lineQuery = this.getProductLinesGQL.watch();
    //     this.lineQuery.valueChanges.subscribe(({data}) => {
    //         this.line = data.productLines as Array<ProductLine>;
    //     });
    // }

    getProducts() {
        this.filter.countries = this.filterCountries.map(e => e.id);
        this.productQuery = this.productsByLinesGQL.watch({lines: []});
        this.productQuery.valueChanges.subscribe(({data}) => {
            this.product = data.productsByLines as Array<Product>;
        });
    }


    onFilter() {
        this.setFilter();
        this.filter.countries = this.filterCountries.map(e => e.id);
        this.searchResultQuery.refetch({filter: this.filter});
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        const start = moment(this.filter.start).format('YYYY-MM-DD');
        const end = moment(this.filter.end).format('YYYY-MM-DD');
        let url = `${environment.base_url}/suggestions/search-suggestion/excel?token=${token}`;
        if (!super.isNullOrUndefined(this.filter.keyword)) {
            url += ('&keyword=' + this.filter.keyword);
        }
        if (!super.isNullOrUndefined(this.filter.line)) {
            url += ('&line=' + this.filter.line);
        }
        if (!super.isNullOrUndefined(this.filter.product)) {
            url += ('&product=' + this.filter.product);
        }
        if (!super.isNullOrUndefined(this.filter.status)) {
            url += ('&status=' + this.filter.status);
        }
        if (!super.isNullOrUndefined(start)) {
            url += ('&start=' + start);
        }
        if (!super.isNullOrUndefined(end)) {
            url += ('&end=' + end);
        }
        this.filter.countries = this.filterCountries.map(e => e.id);
        if (!super.isNullOrUndefined(this.filter.countries) || this.filter.countries.length > 0) {
            url += ('&countries=' + this.filter.countries.join('|'));
        }
        window.open(encodeURI(url));
    }

    actionDelete(idProduct) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningSuggestionDelete'), () => {
            this.removeSuggestion.mutate({id: idProduct}).subscribe((result) => {
                this.searchResultQuery.refetch();
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningSuggestionDeleteSuccess'), 'success');
            });
        });
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'medical-manager':
                return false;
            case 'district-manager':
                return false;
            case 'analyst-marketing':
                return false;
            case 'product-manager':
                return false;
            case 'marketing-manager':
                return false;
            case 'admin':
                return true;
            case 'admin-local':
                return true;
            case 'consult':
                return false;
        }
    }
}
