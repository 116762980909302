import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {
    AnswerCompetenceGQL,
    AnswerSuggestionInput,
    Competence,
    CompetenceGQL,
    CompetenceQuery, User
} from '../../../generated/graphql';
import {TranslateService} from '@ngx-translate/core';
import {QueryRef} from 'apollo-angular';
import {UserService} from '../../service/user.service';

declare var $: any;

@Component({
    selector: 'app-competence-detail',
    templateUrl: './competence-detail.component.html',
    styleUrls: ['./competence-detail.component.scss']
})
export class CompetenceDetailComponent extends BaseComponent implements OnInit {
    user = new User();
    paramId = null;
    competence: Competence = new Competence();
    competenceQuery: QueryRef<CompetenceQuery>;

    competenceAnswer: AnswerSuggestionInput = new AnswerSuggestionInput();

    listFiles = [];
    listFilesImg = [];

    constructor(public router: Router,
                public router2: ActivatedRoute,
                public userService: UserService,
                public loading: NgxSpinnerService,
                public competenceGQL: CompetenceGQL,
                public answerCompetenceGQL: AnswerCompetenceGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.router2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                this.paramId = url.id;
                this.competenceQuery = this.competenceGQL.watch({id: this.paramId});
                this.competenceQuery.valueChanges.subscribe(({data}) => {
                    this.competence = data.competence as Competence;
                    this.listFiles = this.competence.file.filter((x) => x.type !== 'jpg' && x.type !== 'png');
                    this.listFilesImg = this.competence.file.filter((x) => x.type === 'jpg' || x.type === 'png');
                    // this.groupedArrayByProductLine = Object.entries(_(this.budget.product).groupBy(o => o.productLine.name));
                });
            }
        });
    }

    actionAnswerCompetence() {
        // this.competenceAnswer.answer = '';
        // if (this.isNullOrUndefined(this.competenceAnswer.answer)) {
        //     super.showMessage(this.translate.instant('baseComponent.warningAttention'),
        //         this.translate.instant('selecioneresposta'), 'warning');
        //     return;
        // }
        if (!this.isNullOrUndefined(this.competenceAnswer.score) && this.competenceAnswer.score.toString() === '5' && this.isNullOrUndefined(this.competenceAnswer.justificationScore)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningRequiredFields'), 'warning');
            return;
        } else if (!this.isNullOrUndefined(this.competenceAnswer.score) && this.competenceAnswer.score.toString() === 'undefined') {
            this.competenceAnswer.score = undefined;
            this.competenceAnswer.justificationScore = undefined;
        } else if (!this.isNullOrUndefined(this.competenceAnswer.score)) {
            this.competenceAnswer.score = parseInt(this.competenceAnswer.score.toString(), 0);
        }

        this.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('prosseguir'), () => {
            this.answerCompetence();
        });
    }

    answerCompetence() {
        this.competenceAnswer.id = this.paramId;
        this.answerCompetenceGQL.mutate({input: this.competenceAnswer}).subscribe((result) => {
            this.competenceQuery.refetch({id: this.paramId}).then();
            super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningUpdateSuccess'),
                'success');
            // this.router.navigate(['/competence']);
        });
    }

    selectProfile() {
        switch (this.user.profile) {
            case 'medical-manager':
                return false;
            case 'district-manager':
                return false;
            case 'analyst-marketing':
                return false;
            case 'product-manager':
                return true;
            case 'marketing-manager':
                return true;
            case 'admin':
                return true;
            case 'admin-local':
                return true;
            case 'consult':
                return false;
        }
    }

}
