import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export class AnswerConsultInput {
  answer: Scalars['String'];
  id: Scalars['String'];
};

export class AnswerSuggestionInput {
  answer: Scalars['String'];
  id: Scalars['String'];
  justificationScore?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Float']>;
};

export class AuthInput {
  code?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  redirectUri: Scalars['String'];
};

export class AuthType {
  token: Scalars['String'];
  user: User;
};

export class Budget {
  city: City;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  currencyCode?: Maybe<EnumType>;
  date: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  history?: Maybe<Array<BudgetHistory>>;
  id: Scalars['ID'];
  institution: Scalars['String'];
  isCorporate: Scalars['Boolean'];
  product?: Maybe<Array<Product>>;
  quantity: Scalars['Float'];
  response?: Maybe<Scalars['String']>;
  shareValue: Scalars['Float'];
  speciality: Array<Speciality>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class BudgetHistory {
  budget: Budget;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  id: Scalars['ID'];
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class BudgetPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<Budget>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class CardBudgetDashboard {
  approved: Scalars['String'];
  disapproved: Scalars['String'];
  pending: Scalars['String'];
  revision: Scalars['String'];
};

export class CardCompetenceDashboard {
  answered: Scalars['String'];
  pending: Scalars['String'];
};

export class CardConsultDashboard {
  answered: Scalars['String'];
  negative: Scalars['String'];
  pending: Scalars['String'];
  positive: Scalars['String'];
};

export class CardSuggestionDashboard {
  answered: Scalars['String'];
  negative: Scalars['String'];
  pending: Scalars['String'];
  positive: Scalars['String'];
};

export class City {
  budget?: Maybe<Array<Budget>>;
  country?: Maybe<Country>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export class Competence {
  answer?: Maybe<Scalars['String']>;
  answerBy?: Maybe<User>;
  answeredAt?: Maybe<Scalars['DateTime']>;
  country?: Maybe<Country>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  file?: Maybe<Array<CompetenceFile>>;
  id: Scalars['ID'];
  justificationScore?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  score?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class CompetenceFile {
  competence: Competence;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export class CompetencefilterInput {
  countries?: InputMaybe<Array<Scalars['String']>>;
  end: Scalars['DateTime'];
  product?: InputMaybe<Array<Scalars['String']>>;
  productLine?: InputMaybe<Array<Scalars['String']>>;
  start: Scalars['DateTime'];
};

export class Consult {
  answer?: Maybe<Scalars['String']>;
  answerBy?: Maybe<User>;
  answeredAt?: Maybe<Scalars['DateTime']>;
  country?: Maybe<Country>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  deletedAt: Scalars['DateTime'];
  description: Scalars['String'];
  file?: Maybe<Array<ConsultFile>>;
  id: Scalars['ID'];
  likes?: Maybe<Array<ConsultLike>>;
  product?: Maybe<Product>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class ConsultFile {
  consult: Consult;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export class ConsultLike {
  consult: Consult;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  id: Scalars['ID'];
  like: Scalars['Boolean'];
};

export class ConsultResponseDto {
  consult: Consult;
  dislikes: Scalars['Int'];
  likes: Scalars['Int'];
};

export class ConsultfilterInput {
  countries?: InputMaybe<Array<Scalars['String']>>;
  end: Scalars['DateTime'];
  productLines?: InputMaybe<Array<Scalars['String']>>;
  products?: InputMaybe<Array<Scalars['String']>>;
  start: Scalars['DateTime'];
};

export class Contact {
  id: Scalars['ID'];
  message: Scalars['String'];
  subject: Scalars['String'];
  user: User;
};

export class Country {
  city: Array<City>;
  code: Scalars['String'];
  competence: Array<Competence>;
  consult: Array<Competence>;
  deletedAt: Scalars['DateTime'];
  flag: Scalars['String'];
  id: Scalars['ID'];
  managers?: Maybe<Array<ProductManager>>;
  name: Scalars['String'];
  product?: Maybe<Array<Product>>;
  productLine?: Maybe<Array<ProductLine>>;
  suggestion: Array<Competence>;
  users: Array<User>;
};

export class CreateBudgetCityInput {
  id?: InputMaybe<Scalars['String']>;
};

export class CreateBudgetCurrencyInput {
  id?: InputMaybe<Scalars['String']>;
};

export class CreateBudgetInput {
  city: CreateBudgetCityInput;
  currencyCode: CreateBudgetCurrencyInput;
  date: Scalars['DateTime'];
  description: Scalars['String'];
  institution: Scalars['String'];
  isCorporate: Scalars['Boolean'];
  product?: InputMaybe<Array<CreateBudgetProductInput>>;
  quantity: Scalars['Int'];
  shareValue: Scalars['Float'];
  speciality: Array<CreateBudgetSpecialityInput>;
};

export class CreateBudgetProductInput {
  id?: InputMaybe<Scalars['String']>;
};

export class CreateBudgetSpecialityInput {
  id?: InputMaybe<Scalars['String']>;
};

export class CreateCityInput {
  /** Example field (placeholder) */
  exampleField: Scalars['Int'];
};

export class CreateCompetenceCountryInput {
  id?: InputMaybe<Scalars['String']>;
};

export class CreateCompetenceInput {
  country: CreateCompetenceCountryInput;
  description: Scalars['String'];
  product: CreateCompetenceProductInput;
};

export class CreateCompetenceProductInput {
  id?: InputMaybe<Scalars['String']>;
};

export class CreateConsultCountryInput {
  id?: InputMaybe<Scalars['String']>;
};

export class CreateConsultInput {
  country: CreateConsultCountryInput;
  description: Scalars['String'];
  product: CreateConsultProductInput;
};

export class CreateConsultProductInput {
  id?: InputMaybe<Scalars['String']>;
};

export class CreateContactInput {
  message: Scalars['String'];
  subject: Scalars['String'];
};

export class CreateCountryInput {
  flag: Scalars['String'];
  name: Scalars['String'];
};

export class CreateEnumTypeInput {
  description?: InputMaybe<Scalars['String']>;
  detail?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  value: Scalars['String'];
};

export class CreateProductCountryInput {
  id?: InputMaybe<Scalars['String']>;
};

export class CreateProductInput {
  countries: Array<CreateProductCountryInput>;
  name: Scalars['String'];
  productLine: CreateProductLinelInput;
};

export class CreateProductLineCountryInput {
  id: Scalars['String'];
};

export class CreateProductLineInput {
  countries: Array<CreateProductLineCountryInput>;
  name: Scalars['String'];
};

export class CreateProductLinelInput {
  id?: InputMaybe<Scalars['String']>;
};

export class CreateProductManagerCountry {
  id?: InputMaybe<Scalars['String']>;
};

export class CreateProductManagerInput {
  country?: InputMaybe<CreateProductManagerCountry>;
  product: CreateProductManagerProduct;
  /** enum medical-manager|district-manager|analyst-marketing|product-manager|marketing-manager */
  type: Scalars['String'];
  user: CreateProductManagerUser;
};

export class CreateProductManagerProduct {
  id?: InputMaybe<Scalars['String']>;
};

export class CreateProductManagerUser {
  id?: InputMaybe<Scalars['String']>;
};

export class CreateSpecialityInput {
  /** Example field (placeholder) */
  exampleField: Scalars['Int'];
};

export class CreateSuggestionCountryInput {
  id?: InputMaybe<Scalars['String']>;
};

export class CreateSuggestionInput {
  country: CreateSuggestionCountryInput;
  description: Scalars['String'];
  product: CreateSuggestionProductInput;
};

export class CreateSuggestionProductInput {
  id?: InputMaybe<Scalars['String']>;
};

export class CreateUnityInput {
  name: Scalars['String'];
};

export class CreateUserCountryInput {
  id?: InputMaybe<Scalars['String']>;
};

export class CreateUserInput {
  countries: Array<CreateUserCountryInput>;
  email: Scalars['String'];
  name: Scalars['String'];
  /** ENUM user|admin|consult|product-manager|medical-manager|analyst-marketing|marketing-manager|district-manager|admin-local */
  profile: Scalars['String'];
  re: Scalars['String'];
  unityId?: InputMaybe<Scalars['String']>;
};

export class CreateUserNotificationInput {
  message: Scalars['String'];
  relationId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export class DefaultMessage {
  message: Scalars['String'];
  status: Scalars['Int'];
};

export class EnumType {
  budget: Array<Budget>;
  description?: Maybe<Scalars['String']>;
  detail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export class LikeConsultInput {
  consultId: Scalars['String'];
  like: Scalars['Boolean'];
};

export class LikeSuggestionInput {
  like: Scalars['Boolean'];
  suggestionId: Scalars['String'];
};

export class Mutation {
  answerCompetence: Competence;
  answerConsult: Consult;
  answerSuggestion: Suggestion;
  createBudget: Budget;
  createCity: City;
  createCompetence: Competence;
  createConsult: Consult;
  createConsultLike: ConsultLike;
  createContact: Contact;
  /** Create a new Country */
  createCountry: Country;
  /** Create new Enum Type */
  createEnumType: EnumType;
  createProduct: Product;
  createProductLine: ProductLine;
  createProductManager: ProductManager;
  createSpeciality: Speciality;
  createSuggestion: Suggestion;
  createSuggestionLike: SuggestionLike;
  /** Create a new Unity */
  createUnity: Unity;
  /** Create new User */
  createUser: User;
  createUserNotification: UserNotification;
  login: AuthType;
  removeBudget: DefaultMessage;
  removeCity: City;
  removeCompetence: DefaultMessage;
  removeConsult: DefaultMessage;
  removeContact: DefaultMessage;
  /** Remove Country By ID */
  removeCountry: Country;
  removeEnumType: DefaultMessage;
  removeProduct: DefaultMessage;
  removeProductLine: DefaultMessage;
  removeProductManager: DefaultMessage;
  removeSpeciality: Speciality;
  removeSuggestion: DefaultMessage;
  /** Remove Unity */
  removeUnity: Unity;
  /** Remove User By Id */
  removeUser: DefaultMessage;
  removeUserNotification: UserNotification;
  /** set user push token */
  setConfig: DefaultMessage;
  updateBudget: Budget;
  updateBudgetStatus: Budget;
  updateCity: City;
  updateCompetence: Competence;
  updateConsult: Consult;
  updateContact: Contact;
  updateEnumType: EnumType;
  updateProduct: Product;
  updateProductLine: ProductLine;
  updateProductManager: ProductManager;
  updateSpeciality: Speciality;
  updateSuggestion: Suggestion;
  /** Update Unity */
  updateUnity: Unity;
  /** Update User */
  updateUser: User;
  viewNotification: UserNotification;
};


export class MutationAnswerCompetenceArgs {
  data: AnswerCompetenceInput;
};


export class MutationAnswerConsultArgs {
  data: AnswerConsultInput;
};


export class MutationAnswerSuggestionArgs {
  data: AnswerSuggestionInput;
};


export class MutationCreateBudgetArgs {
  data: CreateBudgetInput;
};


export class MutationCreateCityArgs {
  createCityInput: CreateCityInput;
};


export class MutationCreateCompetenceArgs {
  data: CreateCompetenceInput;
};


export class MutationCreateConsultArgs {
  data: CreateConsultInput;
};


export class MutationCreateConsultLikeArgs {
  data: LikeConsultInput;
};


export class MutationCreateContactArgs {
  data: CreateContactInput;
};


export class MutationCreateCountryArgs {
  data: CreateCountryInput;
};


export class MutationCreateEnumTypeArgs {
  data: CreateEnumTypeInput;
};


export class MutationCreateProductArgs {
  data: CreateProductInput;
};


export class MutationCreateProductLineArgs {
  data: CreateProductLineInput;
};


export class MutationCreateProductManagerArgs {
  data: CreateProductManagerInput;
};


export class MutationCreateSpecialityArgs {
  createSpecialityInput: CreateSpecialityInput;
};


export class MutationCreateSuggestionArgs {
  data: CreateSuggestionInput;
};


export class MutationCreateSuggestionLikeArgs {
  data: LikeSuggestionInput;
};


export class MutationCreateUnityArgs {
  data: CreateUnityInput;
};


export class MutationCreateUserArgs {
  data: CreateUserInput;
};


export class MutationCreateUserNotificationArgs {
  data: CreateUserNotificationInput;
};


export class MutationLoginArgs {
  data: AuthInput;
};


export class MutationRemoveBudgetArgs {
  id: Scalars['String'];
};


export class MutationRemoveCityArgs {
  id: Scalars['String'];
};


export class MutationRemoveCompetenceArgs {
  id: Scalars['String'];
};


export class MutationRemoveConsultArgs {
  id: Scalars['String'];
};


export class MutationRemoveContactArgs {
  id: Scalars['String'];
};


export class MutationRemoveCountryArgs {
  id: Scalars['String'];
};


export class MutationRemoveEnumTypeArgs {
  id: Scalars['String'];
};


export class MutationRemoveProductArgs {
  id: Scalars['String'];
};


export class MutationRemoveProductLineArgs {
  id: Scalars['String'];
};


export class MutationRemoveProductManagerArgs {
  id: Scalars['String'];
};


export class MutationRemoveSpecialityArgs {
  id: Scalars['Int'];
};


export class MutationRemoveSuggestionArgs {
  id: Scalars['String'];
};


export class MutationRemoveUnityArgs {
  id: Scalars['String'];
};


export class MutationRemoveUserArgs {
  id: Scalars['String'];
};


export class MutationRemoveUserNotificationArgs {
  id: Scalars['String'];
};


export class MutationSetConfigArgs {
  token: Scalars['String'];
};


export class MutationUpdateBudgetArgs {
  data: UpdateBudgetInput;
};


export class MutationUpdateBudgetStatusArgs {
  data: UpdateBudgetStatusInput;
};


export class MutationUpdateCityArgs {
  data: UpdateCityInput;
};


export class MutationUpdateCompetenceArgs {
  data: UpdateCompetenceInput;
};


export class MutationUpdateConsultArgs {
  data: UpdateConsultInput;
};


export class MutationUpdateContactArgs {
  data: UpdateContactInput;
};


export class MutationUpdateEnumTypeArgs {
  data: UpdateEnumTypeInput;
};


export class MutationUpdateProductArgs {
  data: UpdateProductInput;
};


export class MutationUpdateProductLineArgs {
  data: UpdateProductLineInput;
};


export class MutationUpdateProductManagerArgs {
  data: UpdateProductManagerInput;
};


export class MutationUpdateSpecialityArgs {
  updateSpecialityInput: UpdateSpecialityInput;
};


export class MutationUpdateSuggestionArgs {
  data: UpdateSuggestionInput;
};


export class MutationUpdateUnityArgs {
  data: UpdateUnityInput;
};


export class MutationUpdateUserArgs {
  data: UpdateUserInput;
};


export class MutationViewNotificationArgs {
  data: UpdateUserNotificationInput;
};

export class Product {
  budget: Array<Budget>;
  competence: Array<Competence>;
  consult: Array<Consult>;
  countries: Array<Country>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  managers?: Maybe<Array<ProductManager>>;
  name: Scalars['String'];
  productLine?: Maybe<ProductLine>;
  suggestion: Array<Suggestion>;
};

export class ProductLine {
  countries: Array<Country>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  product?: Maybe<Array<Product>>;
};

export class ProductLinePage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<ProductLine>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class ProductLineRankingDto {
  percent: Scalars['Float'];
  productLine: Scalars['String'];
  ranking: Array<RankingByCountryDto>;
  totalAnswered: Scalars['Float'];
};

export class ProductManager {
  country?: Maybe<Country>;
  id: Scalars['ID'];
  product?: Maybe<Product>;
  type: Scalars['String'];
  user?: Maybe<User>;
};

export class ProductPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<Product>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class QuarterDto {
  quarter: Scalars['String'];
};

export class Query {
  budget: Budget;
  budgetUsers: Array<User>;
  budgets: Array<Budget>;
  /** Return statistic count */
  cardBudgetCount: CardBudgetDashboard;
  /** Return statistic count */
  cardCompetenceCount: CardCompetenceDashboard;
  /** Return statistic count */
  cardConsultCount: CardConsultDashboard;
  /** Return statistic count */
  cardSuggestionCount: CardSuggestionDashboard;
  cities: Array<City>;
  citiesByCountries: Array<City>;
  citiesByCountry: Array<City>;
  city: City;
  competence: Competence;
  competences: Array<Competence>;
  competencesByUser: Array<Competence>;
  consult: Consult;
  consultByUser: Array<Consult>;
  consultLike?: Maybe<ConsultLike>;
  consultLikes: ConsultResponseDto;
  consults: Array<Consult>;
  contact: Contact;
  contacts: Array<Contact>;
  /** Return All Countries */
  countries: Array<Country>;
  countriesByUser: Array<Country>;
  /** Get Country By ID */
  country: Country;
  /** Return User Logged */
  currentUser: User;
  enumByType: Array<EnumType>;
  enumType: EnumType;
  enumTypes: Array<EnumType>;
  findBudgetByDate: Array<Budget>;
  /** Return User By Email */
  findUserByEmail: User;
  /** Return All Users from profile */
  findUsersByProfile: Array<User>;
  /** Return quarters for ranking */
  getRanking: Array<RankingDto>;
  /** Return quarters for ranking by country */
  getRankingByCountry: Array<ProductLineRankingDto>;
  /** Return unscored total */
  getUnScoredTotal: UnScoredTotalDto;
  /** Return list quarters for ranking */
  listQuarters: Array<QuarterDto>;
  product: Product;
  productLine: ProductLine;
  productLines: Array<ProductLine>;
  products: Array<Product>;
  productsByLines: Array<Product>;
  search: Search;
  searchBudgets: BudgetPage;
  searchCompetence: Array<Competence>;
  searchCompetenceAll: Array<Competence>;
  searchConsult: Array<Consult>;
  searchConsultAll: Array<ConsultResponseDto>;
  searchProduct: ProductPage;
  searchProductLine: Array<ProductLine>;
  searchProductLinePage: ProductLinePage;
  searchProductLinesByCountry: Array<ProductLine>;
  searchProductsAll: Array<Product>;
  searchProductsByCountry: Array<Product>;
  searchSuggestion: Array<Suggestion>;
  searchSuggestionsAll: Array<SuggestionResponseDto>;
  searchUsers: UserPage;
  /** Search users */
  searchUsersAll: Array<User>;
  specialities: Array<Speciality>;
  speciality: Speciality;
  /** Return statistic count */
  statisticCount: StatisticDashboard;
  suggestion: Suggestion;
  suggestionLike?: Maybe<SuggestionLike>;
  suggestionLikes: SuggestionResponseDto;
  suggestions: Array<Suggestion>;
  suggestionsByUser: Array<Suggestion>;
  /** Return Top count */
  topBudgetCount: Array<TopBudgetDashboard>;
  /** Return Top count */
  topCompetenceCount: Array<TopCompetenceDashboard>;
  /** Return Top count */
  topConsultCount: Array<TopConsultDashboard>;
  /** Return Top count */
  topSuggestionCount: Array<TopSuggestionDashboard>;
  /** Return All Unities */
  unities: Array<Unity>;
  /** Get Unity By ID */
  unity: Unity;
  /** Return User By Id */
  user: User;
  userNotification: UserNotification;
  userNotifications: UserNotificationsObject;
  /** Return All Users */
  users: Array<User>;
};


export class QueryBudgetArgs {
  id: Scalars['String'];
};


export class QueryBudgetUsersArgs {
  city: Scalars['String'];
};


export class QueryCardBudgetCountArgs {
  data: SearchStatisticInput;
};


export class QueryCardCompetenceCountArgs {
  data: SearchStatisticInput;
};


export class QueryCardConsultCountArgs {
  data: SearchStatisticInput;
};


export class QueryCardSuggestionCountArgs {
  data: SearchStatisticInput;
};


export class QueryCitiesByCountriesArgs {
  ids: Array<Scalars['String']>;
};


export class QueryCitiesByCountryArgs {
  id: Scalars['String'];
};


export class QueryCityArgs {
  id: Scalars['String'];
};


export class QueryCompetenceArgs {
  id: Scalars['String'];
};


export class QueryConsultArgs {
  id: Scalars['String'];
};


export class QueryConsultLikeArgs {
  id: Scalars['String'];
};


export class QueryConsultLikesArgs {
  id: Scalars['String'];
};


export class QueryContactArgs {
  id: Scalars['String'];
};


export class QueryCountriesByUserArgs {
  id?: InputMaybe<Scalars['String']>;
};


export class QueryCountryArgs {
  id: Scalars['String'];
};


export class QueryEnumByTypeArgs {
  type: Scalars['String'];
};


export class QueryEnumTypeArgs {
  id: Scalars['String'];
};


export class QueryFindBudgetByDateArgs {
  month: Scalars['Int'];
  year: Scalars['Int'];
};


export class QueryFindUserByEmailArgs {
  email: Scalars['String'];
};


export class QueryFindUsersByProfileArgs {
  profile: Scalars['String'];
};


export class QueryGetRankingArgs {
  productLineId: Scalars['String'];
  quarter: Scalars['String'];
};


export class QueryGetRankingByCountryArgs {
  quarter: Scalars['String'];
};


export class QueryGetUnScoredTotalArgs {
  productLineId: Scalars['String'];
  quarter: Scalars['String'];
};


export class QueryProductArgs {
  id: Scalars['String'];
};


export class QueryProductLineArgs {
  id: Scalars['String'];
};


export class QueryProductsByLinesArgs {
  lines?: InputMaybe<Array<Scalars['String']>>;
};


export class QuerySearchArgs {
  keyword: Scalars['String'];
};


export class QuerySearchBudgetsArgs {
  filter: SearchBudgetAll;
};


export class QuerySearchCompetenceArgs {
  filter: CompetencefilterInput;
};


export class QuerySearchCompetenceAllArgs {
  filters: SearchCompetenceAll;
};


export class QuerySearchConsultArgs {
  filter: ConsultfilterInput;
};


export class QuerySearchConsultAllArgs {
  filter: SearchConsultAll;
};


export class QuerySearchProductArgs {
  filter: SearchProduct;
};


export class QuerySearchProductLineArgs {
  filters: SearchProductLine;
};


export class QuerySearchProductLinePageArgs {
  filters: SearchProductLine;
};


export class QuerySearchProductLinesByCountryArgs {
  countryId?: InputMaybe<Array<Scalars['String']>>;
};


export class QuerySearchProductsAllArgs {
  filter: SearchProductAll;
};


export class QuerySearchProductsByCountryArgs {
  countryId: Scalars['String'];
  productLine?: InputMaybe<Scalars['String']>;
};


export class QuerySearchSuggestionArgs {
  filter: SuggestionfilterInput;
};


export class QuerySearchSuggestionsAllArgs {
  filter: SearchSuggestionAll;
};


export class QuerySearchUsersArgs {
  filter: SearchUserAll;
};


export class QuerySearchUsersAllArgs {
  filter: SearchUserAll;
};


export class QuerySpecialitiesArgs {
  culture?: InputMaybe<Scalars['String']>;
};


export class QuerySpecialityArgs {
  id: Scalars['Int'];
};


export class QueryStatisticCountArgs {
  data: SearchStatisticInput;
};


export class QuerySuggestionArgs {
  id: Scalars['String'];
};


export class QuerySuggestionLikeArgs {
  id: Scalars['String'];
};


export class QuerySuggestionLikesArgs {
  id: Scalars['String'];
};


export class QueryTopBudgetCountArgs {
  data: SearchStatisticInput;
};


export class QueryTopCompetenceCountArgs {
  data: SearchStatisticInput;
};


export class QueryTopConsultCountArgs {
  data: SearchStatisticInput;
};


export class QueryTopSuggestionCountArgs {
  data: SearchStatisticInput;
};


export class QueryUnityArgs {
  id: Scalars['String'];
};


export class QueryUserArgs {
  id: Scalars['String'];
};


export class QueryUserNotificationArgs {
  id: Scalars['String'];
};

export class RankingByCountryDto {
  name: Scalars['String'];
  position: Scalars['Float'];
  totalScore: Scalars['Float'];
};

export class RankingDto {
  name: Scalars['String'];
  position: Scalars['Float'];
  totalScore: Scalars['Float'];
};

export class Search {
  budget?: Maybe<Array<Budget>>;
  competence?: Maybe<Array<Competence>>;
  consult?: Maybe<Array<Consult>>;
  suggestion?: Maybe<Array<Suggestion>>;
};

export class SearchBudgetAll {
  /** if true search by event date else by event createdAt date */
  byEvent?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  countries?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  line?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  product?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
};

export class SearchCompetenceAll {
  countries?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  line?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
};

export class SearchConsultAll {
  countries?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  line?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
};

export class SearchProduct {
  countries?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  line?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchProductAll {
  countries?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  line?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchProductLine {
  countries?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchStatisticInput {
  country?: InputMaybe<Scalars['String']>;
  end: Scalars['DateTime'];
  line?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
  start: Scalars['DateTime'];
};

export class SearchSuggestionAll {
  countries?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  line?: InputMaybe<Array<Scalars['String']>>;
  product?: InputMaybe<Array<Scalars['String']>>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
};

export class SearchUserAll {
  countries?: InputMaybe<Array<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Scalars['String']>;
  unity?: InputMaybe<Scalars['String']>;
};

export class Speciality {
  budget: Array<Budget>;
  culture: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  priority: Scalars['Float'];
};

export class StatisticDashboard {
  budget: Scalars['String'];
  competence: Scalars['String'];
  consult: Scalars['String'];
  suggestion: Scalars['String'];
};

export class Suggestion {
  answer?: Maybe<Scalars['String']>;
  answerBy?: Maybe<User>;
  answeredAt?: Maybe<Scalars['DateTime']>;
  country?: Maybe<Country>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  deletedAt: Scalars['DateTime'];
  description: Scalars['String'];
  file?: Maybe<Array<SuggestionFile>>;
  id: Scalars['ID'];
  justificationScore?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<SuggestionLike>>;
  product: Product;
  score?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class SuggestionFile {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  suggestion: Suggestion;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export class SuggestionLike {
  createdAt: Scalars['DateTime'];
  createdBy: User;
  id: Scalars['ID'];
  like: Scalars['Boolean'];
  suggestion: Suggestion;
};

export class SuggestionResponseDto {
  dislikes: Scalars['Int'];
  likes: Scalars['Int'];
  suggestion: Suggestion;
};

export class SuggestionfilterInput {
  countries?: InputMaybe<Array<Scalars['String']>>;
  end: Scalars['DateTime'];
  productLines?: InputMaybe<Array<Scalars['String']>>;
  products?: InputMaybe<Array<Scalars['String']>>;
  start: Scalars['DateTime'];
};

export class TopBudgetDashboard {
  count: Scalars['String'];
  name: Scalars['String'];
};

export class TopCompetenceDashboard {
  count: Scalars['String'];
  name: Scalars['String'];
};

export class TopConsultDashboard {
  count: Scalars['String'];
  name: Scalars['String'];
};

export class TopSuggestionDashboard {
  count: Scalars['String'];
  name: Scalars['String'];
};

export class UnScoredTotalDto {
  total: Scalars['Float'];
};

export class Unity {
  id: Scalars['ID'];
  name: Scalars['String'];
  users: Array<User>;
};

export class UpdateBudgetInput {
  city: CreateBudgetCityInput;
  currencyCode: CreateBudgetCurrencyInput;
  date: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  institution: Scalars['String'];
  isCorporate: Scalars['Boolean'];
  product?: InputMaybe<Array<CreateBudgetProductInput>>;
  quantity: Scalars['Int'];
  response?: InputMaybe<Scalars['String']>;
  shareValue: Scalars['Float'];
  speciality: Array<CreateBudgetSpecialityInput>;
  status?: InputMaybe<Scalars['String']>;
};

export class UpdateBudgetStatusInput {
  id: Scalars['String'];
  response?: InputMaybe<Scalars['String']>;
  /** enum: pending|approved|revision|disapproved|pending-product|disapproved-product */
  status: Scalars['String'];
};

export class UpdateCityInput {
  /** Example field (placeholder) */
  exampleField?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
};

export class UpdateCompetenceInput {
  country?: InputMaybe<CreateCompetenceCountryInput>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  product?: InputMaybe<CreateCompetenceProductInput>;
};

export class UpdateConsultInput {
  country?: InputMaybe<CreateConsultCountryInput>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  product?: InputMaybe<CreateConsultProductInput>;
};

export class UpdateContactInput {
  id: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

export class UpdateEnumTypeInput {
  description?: InputMaybe<Scalars['String']>;
  detail?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export class UpdateProductInput {
  countries: Array<CreateProductCountryInput>;
  id: Scalars['String'];
  name: Scalars['String'];
  productLine: CreateProductLinelInput;
};

export class UpdateProductLineInput {
  countries?: InputMaybe<Array<CreateProductLineCountryInput>>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export class UpdateProductManagerInput {
  id: Scalars['String'];
  user: CreateProductManagerUser;
};

export class UpdateSpecialityInput {
  /** Example field (placeholder) */
  exampleField?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
};

export class UpdateSuggestionInput {
  country?: InputMaybe<CreateSuggestionCountryInput>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  product?: InputMaybe<CreateSuggestionProductInput>;
};

export class UpdateUnityInput {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export class UpdateUserInput {
  countries: Array<CreateUserCountryInput>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  /** ENUM user|admin|consult|product-manager|medical-manager|analyst-marketing|marketing-manager|district-manager|admin-local */
  profile?: InputMaybe<Scalars['String']>;
  re?: InputMaybe<Scalars['String']>;
  unityId?: InputMaybe<Scalars['String']>;
};

export class UpdateUserNotificationInput {
  id: Scalars['String'];
  isRead: Scalars['Boolean'];
};

export class User {
  budget?: Maybe<Array<Budget>>;
  budgetHistory?: Maybe<Array<BudgetHistory>>;
  competenceAnswer: Array<Competence>;
  competenceCreate: Array<Competence>;
  consultAnswer: Array<Consult>;
  consultCreate: Array<Consult>;
  consultLike: Array<ConsultLike>;
  contacts: Array<Contact>;
  countries: Array<Country>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<Array<UserNotification>>;
  photo?: Maybe<Scalars['String']>;
  productManager?: Maybe<Array<ProductManager>>;
  profile?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  re: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  suggestionAswer: Array<Suggestion>;
  suggestionCreate: Array<Suggestion>;
  suggestionLike: Array<SuggestionLike>;
  unity?: Maybe<Unity>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class UserNotification {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  message: Scalars['String'];
  relationId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export class UserNotificationsObject {
  count: Scalars['Int'];
  notifications: Array<UserNotification>;
};

export class UserPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<User>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class AnswerCompetenceInput {
  answer: Scalars['String'];
  id: Scalars['String'];
  justificationScore?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Float']>;
};

export type CountryFragment = { id: string, name: string, flag: string };

export type CountriesFragment = { id: string, name: string, flag: string };

export type UnityFragment = { id: string, name: string };

export type UserFragment = { id: string, name?: string | null | undefined, email?: string | null | undefined, re: string, profile?: string | null | undefined, photo?: string | null | undefined, status?: string | null | undefined, unity?: { id: string, name: string } | null | undefined, countries: Array<{ id: string, name: string, flag: string }> };

export type BudgetFragment = { id: string, date: any, institution: string, quantity: number, isCorporate: boolean, shareValue: number, description: string, response?: string | null | undefined, status: string, createdAt: any, updatedAt: any, history?: Array<{ id: string, status: string, createdAt: any, updatedAt: any, createdBy: { id: string, name?: string | null | undefined, email?: string | null | undefined, re: string, profile?: string | null | undefined, photo?: string | null | undefined } }> | null | undefined, city: { id: string, name: string, country?: { name: string, flag: string } | null | undefined }, createdBy: { name?: string | null | undefined }, speciality: Array<{ id: string, name: string }>, product?: Array<{ id: string, name: string, productLine?: { id: string, name: string } | null | undefined }> | null | undefined };

export type CompetenceFragment = { id: string, answeredAt?: any | null | undefined, answer?: string | null | undefined, status: string, description: string, createdAt: any, updatedAt: any, score?: number | null | undefined, justificationScore?: string | null | undefined, country?: { name: string, flag: string } | null | undefined, product?: { id: string, name: string, managers?: Array<{ id: string, user?: { id: string, name?: string | null | undefined } | null | undefined }> | null | undefined, productLine?: { id: string, name: string } | null | undefined } | null | undefined, createdBy?: { name?: string | null | undefined, profile?: string | null | undefined, countries: Array<{ name: string, flag: string }> } | null | undefined, answerBy?: { name?: string | null | undefined, profile?: string | null | undefined } | null | undefined, file?: Array<{ id: string, url: string, type: string }> | null | undefined };

export type StatisticDashboardFragment = { consult: string, suggestion: string, competence: string, budget: string };

export type ConsultLikeFragment = { id: string, like: boolean, createdAt: any, consult: { id: string, description: string, status: string, answer?: string | null | undefined, answeredAt?: any | null | undefined, createdAt: any, updatedAt: any, file?: Array<{ id: string, url: string, type: string, createdAt: any, updatedAt: any }> | null | undefined, country?: { flag: string, name: string } | null | undefined, answerBy?: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined } | null | undefined, createdBy: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined }, product?: { id: string, name: string, productLine?: { id: string, name: string } | null | undefined } | null | undefined }, createdBy: { id: string, name?: string | null | undefined, email?: string | null | undefined, re: string, profile?: string | null | undefined, photo?: string | null | undefined, status?: string | null | undefined, unity?: { id: string, name: string } | null | undefined, countries: Array<{ id: string, name: string, flag: string }> } };

export type ConsultFileFragment = { id: string, url: string, type: string, createdAt: any, updatedAt: any };

export type SuggestionFileFragment = { id: string, url: string, type: string, createdAt: any, updatedAt: any };

export type ConsultFragment = { id: string, description: string, status: string, answer?: string | null | undefined, answeredAt?: any | null | undefined, createdAt: any, updatedAt: any, file?: Array<{ id: string, url: string, type: string, createdAt: any, updatedAt: any }> | null | undefined, country?: { flag: string, name: string } | null | undefined, answerBy?: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined } | null | undefined, createdBy: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined }, product?: { id: string, name: string, productLine?: { id: string, name: string } | null | undefined } | null | undefined };

export type SuggestionFragment = { id: string, description: string, status: string, answer?: string | null | undefined, answeredAt?: any | null | undefined, createdAt: any, updatedAt: any, score?: number | null | undefined, justificationScore?: string | null | undefined, file?: Array<{ id: string, url: string, type: string, createdAt: any, updatedAt: any }> | null | undefined, country?: { flag: string, name: string } | null | undefined, answerBy?: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined } | null | undefined, createdBy: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined }, product: { id: string, name: string, managers?: Array<{ id: string, user?: { name?: string | null | undefined } | null | undefined }> | null | undefined, productLine?: { id: string, name: string } | null | undefined } };

export type ConsultResponseDtoFragment = { dislikes: number, likes: number, consult: { id: string, description: string, status: string, answer?: string | null | undefined, answeredAt?: any | null | undefined, createdAt: any, updatedAt: any, file?: Array<{ id: string, url: string, type: string, createdAt: any, updatedAt: any }> | null | undefined, country?: { flag: string, name: string } | null | undefined, answerBy?: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined } | null | undefined, createdBy: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined }, product?: { id: string, name: string, productLine?: { id: string, name: string } | null | undefined } | null | undefined } };

export type SuggestionResponseDtoFragment = { dislikes: number, likes: number, suggestion: { id: string, description: string, status: string, answer?: string | null | undefined, answeredAt?: any | null | undefined, createdAt: any, updatedAt: any, score?: number | null | undefined, justificationScore?: string | null | undefined, file?: Array<{ id: string, url: string, type: string, createdAt: any, updatedAt: any }> | null | undefined, country?: { flag: string, name: string } | null | undefined, answerBy?: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined } | null | undefined, createdBy: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined }, product: { id: string, name: string, managers?: Array<{ id: string, user?: { name?: string | null | undefined } | null | undefined }> | null | undefined, productLine?: { id: string, name: string } | null | undefined } } };

export type SearchBudgetsQueryVariables = Exact<{
  filter: SearchBudgetAll;
}>;


export type SearchBudgetsQuery = { searchBudgets: { currentPage: number, nextPage: boolean, pageSize: number, previousPage: boolean, totalCount: number, totalPage: number, results: Array<{ id: string, date: any, shareValue: number, status: string, createdBy: { name?: string | null | undefined }, city: { name: string, country?: { id: string, name: string, flag: string } | null | undefined } }> } };

export type ProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsQuery = { products: Array<{ id: string, name: string }> };

export type ProductsByLinesQueryVariables = Exact<{
  lines?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ProductsByLinesQuery = { productsByLines: Array<{ id: string, name: string }> };

export type CitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type CitiesQuery = { cities: Array<{ id: string, name: string, country?: { flag: string, name: string } | null | undefined }> };

export type BudgetQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type BudgetQuery = { budget: { id: string, date: any, institution: string, quantity: number, isCorporate: boolean, shareValue: number, description: string, response?: string | null | undefined, status: string, createdAt: any, updatedAt: any, history?: Array<{ id: string, status: string, createdAt: any, updatedAt: any, createdBy: { id: string, name?: string | null | undefined, email?: string | null | undefined, re: string, profile?: string | null | undefined, photo?: string | null | undefined } }> | null | undefined, city: { id: string, name: string, country?: { name: string, flag: string } | null | undefined }, createdBy: { name?: string | null | undefined }, speciality: Array<{ id: string, name: string }>, product?: Array<{ id: string, name: string, productLine?: { id: string, name: string } | null | undefined }> | null | undefined } };

export type RemoveBudgetMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveBudgetMutation = { removeBudget: { message: string, status: number } };

export type UpdateBudgetStatusMutationVariables = Exact<{
  input: UpdateBudgetStatusInput;
}>;


export type UpdateBudgetStatusMutation = { updateBudgetStatus: { id: string, date: any, institution: string, quantity: number, isCorporate: boolean, shareValue: number, description: string, response?: string | null | undefined, status: string, createdAt: any, updatedAt: any, history?: Array<{ id: string, status: string, createdAt: any, updatedAt: any, createdBy: { id: string, name?: string | null | undefined, email?: string | null | undefined, re: string, profile?: string | null | undefined, photo?: string | null | undefined } }> | null | undefined, city: { id: string, name: string, country?: { name: string, flag: string } | null | undefined }, createdBy: { name?: string | null | undefined }, speciality: Array<{ id: string, name: string }>, product?: Array<{ id: string, name: string, productLine?: { id: string, name: string } | null | undefined }> | null | undefined } };

export type SearchCompetenceAllQueryVariables = Exact<{
  filter: SearchCompetenceAll;
}>;


export type SearchCompetenceAllQuery = { searchCompetenceAll: Array<{ id: string, answeredAt?: any | null | undefined, answer?: string | null | undefined, status: string, description: string, createdAt: any, updatedAt: any, score?: number | null | undefined, justificationScore?: string | null | undefined, country?: { name: string, flag: string } | null | undefined, product?: { id: string, name: string, managers?: Array<{ id: string, user?: { id: string, name?: string | null | undefined } | null | undefined }> | null | undefined, productLine?: { id: string, name: string } | null | undefined } | null | undefined, createdBy?: { name?: string | null | undefined, profile?: string | null | undefined, countries: Array<{ name: string, flag: string }> } | null | undefined, answerBy?: { name?: string | null | undefined, profile?: string | null | undefined } | null | undefined, file?: Array<{ id: string, url: string, type: string }> | null | undefined }> };

export type CompetenceQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CompetenceQuery = { competence: { id: string, answeredAt?: any | null | undefined, answer?: string | null | undefined, status: string, description: string, createdAt: any, updatedAt: any, score?: number | null | undefined, justificationScore?: string | null | undefined, country?: { name: string, flag: string } | null | undefined, product?: { id: string, name: string, managers?: Array<{ id: string, user?: { id: string, name?: string | null | undefined } | null | undefined }> | null | undefined, productLine?: { id: string, name: string } | null | undefined } | null | undefined, createdBy?: { name?: string | null | undefined, profile?: string | null | undefined, countries: Array<{ name: string, flag: string }> } | null | undefined, answerBy?: { name?: string | null | undefined, profile?: string | null | undefined } | null | undefined, file?: Array<{ id: string, url: string, type: string }> | null | undefined } };

export type RemoveCompetenceMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveCompetenceMutation = { removeCompetence: { message: string, status: number } };

export type AnswerCompetenceMutationVariables = Exact<{
  input: AnswerCompetenceInput;
}>;


export type AnswerCompetenceMutation = { answerCompetence: { id: string, answeredAt?: any | null | undefined, answer?: string | null | undefined, status: string, description: string, createdAt: any, updatedAt: any, score?: number | null | undefined, justificationScore?: string | null | undefined, country?: { name: string, flag: string } | null | undefined, product?: { id: string, name: string, managers?: Array<{ id: string, user?: { id: string, name?: string | null | undefined } | null | undefined }> | null | undefined, productLine?: { id: string, name: string } | null | undefined } | null | undefined, createdBy?: { name?: string | null | undefined, profile?: string | null | undefined, countries: Array<{ name: string, flag: string }> } | null | undefined, answerBy?: { name?: string | null | undefined, profile?: string | null | undefined } | null | undefined, file?: Array<{ id: string, url: string, type: string }> | null | undefined } };

export type GetConsultQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetConsultQuery = { consult: { id: string, status: string, description: string, answer?: string | null | undefined, answeredAt?: any | null | undefined, createdAt: any, updatedAt: any, answerBy?: { id: string, name?: string | null | undefined } | null | undefined } };

export type GetConsultLikesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetConsultLikesQuery = { consultLikes: { dislikes: number, likes: number, consult: { id: string, description: string, status: string, answer?: string | null | undefined, answeredAt?: any | null | undefined, createdAt: any, updatedAt: any, file?: Array<{ id: string, url: string, type: string, createdAt: any, updatedAt: any }> | null | undefined, country?: { flag: string, name: string } | null | undefined, answerBy?: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined } | null | undefined, createdBy: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined }, product?: { id: string, name: string, productLine?: { id: string, name: string } | null | undefined } | null | undefined } } };

export type GetConsultsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConsultsQuery = { consults: Array<{ id: string, status: string, description: string, answer?: string | null | undefined, answeredAt?: any | null | undefined, createdAt: any, updatedAt: any, answerBy?: { id: string, name?: string | null | undefined } | null | undefined, country?: { flag: string, name: string } | null | undefined }> };

export type SearchConsultAllQueryVariables = Exact<{
  input: SearchConsultAll;
}>;


export type SearchConsultAllQuery = { searchConsultAll: Array<{ dislikes: number, likes: number, consult: { id: string, description: string, status: string, answer?: string | null | undefined, answeredAt?: any | null | undefined, createdAt: any, updatedAt: any, country?: { flag: string, name: string } | null | undefined, answerBy?: { id: string, name?: string | null | undefined } | null | undefined, createdBy: { id: string, name?: string | null | undefined }, product?: { id: string, name: string, productLine?: { id: string, name: string } | null | undefined } | null | undefined } }> };

export type CreateConsultMutationVariables = Exact<{
  input: CreateConsultInput;
}>;


export type CreateConsultMutation = { createConsult: { id: string, status: string, description: string, answer?: string | null | undefined, answeredAt?: any | null | undefined, createdAt: any, updatedAt: any, answerBy?: { id: string, name?: string | null | undefined } | null | undefined } };

export type UpdateConsultMutationVariables = Exact<{
  input: UpdateConsultInput;
}>;


export type UpdateConsultMutation = { updateConsult: { id: string, status: string, description: string, answer?: string | null | undefined, answeredAt?: any | null | undefined, createdAt: any, updatedAt: any, answerBy?: { id: string, name?: string | null | undefined } | null | undefined } };

export type AnswerConsultMutationVariables = Exact<{
  input: AnswerConsultInput;
}>;


export type AnswerConsultMutation = { answerConsult: { id: string, answer?: string | null | undefined } };

export type RemoveConsultUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveConsultUserMutation = { removeUser: { message: string } };

export type RemoveConsultMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveConsultMutation = { removeConsult: { message: string, status: number } };

export type GetCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountriesQuery = { countries: Array<{ id: string, name: string, flag: string }> };

export type GetCountriesByUserIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCountriesByUserIdQuery = { countriesByUser: Array<{ id: string, name: string, flag: string }> };

export type GetCountriesByUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountriesByUserQuery = { countriesByUser: Array<{ id: string, name: string, flag: string }> };

export type GetCitiesByCountriesQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetCitiesByCountriesQuery = { citiesByCountries: Array<{ id: string, name: string }> };

export type StatisticCountQueryVariables = Exact<{
  input: SearchStatisticInput;
}>;


export type StatisticCountQuery = { statisticCount: { consult: string, suggestion: string, competence: string, budget: string } };

export type TopQueryVariables = Exact<{
  input: SearchStatisticInput;
}>;


export type TopQuery = { topBudgetCount: Array<{ name: string, count: string }>, topConsultCount: Array<{ name: string, count: string }>, topCompetenceCount: Array<{ name: string, count: string }>, topSuggestionCount: Array<{ name: string, count: string }> };

export type CardQueryVariables = Exact<{
  input: SearchStatisticInput;
}>;


export type CardQuery = { cardConsultCount: { pending: string, answered: string, positive: string, negative: string }, cardSuggestionCount: { pending: string, answered: string, positive: string, negative: string }, cardCompetenceCount: { pending: string, answered: string }, cardBudgetCount: { approved: string, pending: string, revision: string, disapproved: string } };

export type ListQuartersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListQuartersQuery = { listQuarters: Array<{ quarter: string }> };

export type ProductLinesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductLinesQuery = { productLines: Array<{ id: string, name: string }> };

export type GetRankingQueryVariables = Exact<{
  quarter: Scalars['String'];
  productLineId: Scalars['String'];
}>;


export type GetRankingQuery = { getRanking: Array<{ position: number, name: string, totalScore: number }> };

export type GetUnScoredTotalQueryVariables = Exact<{
  quarter: Scalars['String'];
  productLineId: Scalars['String'];
}>;


export type GetUnScoredTotalQuery = { getUnScoredTotal: { total: number } };

export type GetRankingByCountryQueryVariables = Exact<{
  quarter: Scalars['String'];
}>;


export type GetRankingByCountryQuery = { getRankingByCountry: Array<{ productLine: string, percent: number, totalAnswered: number, ranking: Array<{ totalScore: number, name: string, position: number }> }> };

export type SearchProductLineQueryVariables = Exact<{
  input: SearchProductLine;
}>;


export type SearchProductLineQuery = { searchProductLine: Array<{ id: string, name: string, countries: Array<{ id: string, name: string, flag: string }> }> };

export type SearchProductLinePageQueryVariables = Exact<{
  input: SearchProductLine;
}>;


export type SearchProductLinePageQuery = { searchProductLinePage: { currentPage: number, nextPage: boolean, pageSize: number, previousPage: boolean, totalCount: number, totalPage: number, results: Array<{ id: string, name: string, countries: Array<{ id: string, name: string, flag: string }> }> } };

export type SearchProductLinesByCountryQueryVariables = Exact<{
  id?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SearchProductLinesByCountryQuery = { searchProductLinesByCountry: Array<{ id: string, name: string }> };

export type GetProductLinesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductLinesQuery = { productLines: Array<{ id: string, name: string }> };

export type CreateLineMutationVariables = Exact<{
  input: CreateProductLineInput;
}>;


export type CreateLineMutation = { createProductLine: { name: string } };

export type UpdateLineMutationVariables = Exact<{
  input: UpdateProductLineInput;
}>;


export type UpdateLineMutation = { updateProductLine: { id: string, name: string } };

export type RemoveLineMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveLineMutation = { removeProductLine: { message: string } };

export type LoginMutationVariables = Exact<{
  code: Scalars['String'];
  redirectUri: Scalars['String'];
  email: Scalars['String'];
}>;


export type LoginMutation = { login: { token: string, user: { id: string, name?: string | null | undefined, email?: string | null | undefined, re: string, photo?: string | null | undefined, profile?: string | null | undefined, status?: string | null | undefined, createdAt: any, deletedAt?: any | null | undefined, updatedAt?: any | null | undefined, createdBy?: { id: string, name?: string | null | undefined } | null | undefined } } };

export type SearchProductsAllQueryVariables = Exact<{
  filter: SearchProductAll;
}>;


export type SearchProductsAllQuery = { searchProductsAll: Array<{ id: string, name: string, productLine?: { id: string, name: string } | null | undefined, managers?: Array<{ id: string, type: string, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined } | null | undefined }> | null | undefined, countries: Array<{ id: string, name: string }> }> };

export type SearchProductsQueryVariables = Exact<{
  filter: SearchProduct;
}>;


export type SearchProductsQuery = { searchProduct: { currentPage: number, nextPage: boolean, pageSize: number, previousPage: boolean, totalCount: number, totalPage: number, results: Array<{ id: string, name: string, productLine?: { id: string, name: string } | null | undefined, managers?: Array<{ id: string, type: string, country?: { id: string, name: string } | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, profile?: string | null | undefined } | null | undefined }> | null | undefined, countries: Array<{ id: string, name: string }> }> } };

export type GetProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductsQuery = { products: Array<{ id: string, name: string }> };

export type GetProductsByLineQueryVariables = Exact<{
  lines?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetProductsByLineQuery = { productsByLines: Array<{ id: string, name: string }> };

export type GetUserByProfileQueryVariables = Exact<{
  profile: Scalars['String'];
}>;


export type GetUserByProfileQuery = { findUsersByProfile: Array<{ id: string, name?: string | null | undefined, email?: string | null | undefined, profile?: string | null | undefined }> };

export type GetProductByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetProductByIdQuery = { product: { id: string, name: string, productLine?: { id: string, name: string } | null | undefined, managers?: Array<{ id: string, type: string, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined } | null | undefined }> | null | undefined } };

export type RemoveProductMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveProductMutation = { removeProduct: { message: string } };

export type UpdateProductMutationVariables = Exact<{
  input: UpdateProductInput;
}>;


export type UpdateProductMutation = { updateProduct: { id: string, name: string } };

export type CreateProductMutationVariables = Exact<{
  input: CreateProductInput;
}>;


export type CreateProductMutation = { createProduct: { id: string, name: string, productLine?: { id: string, name: string } | null | undefined, managers?: Array<{ id: string, type: string, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, profile?: string | null | undefined, countries: Array<{ id: string, name: string, flag: string }> } | null | undefined }> | null | undefined } };

export type CreateProductManagerMutationVariables = Exact<{
  input: CreateProductManagerInput;
}>;


export type CreateProductManagerMutation = { createProductManager: { id: string, type: string, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined } | null | undefined, country?: { id: string, name: string } | null | undefined } };

export type RemoveProductManagerMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveProductManagerMutation = { removeProductManager: { message: string } };

export type SearchSuggestionQueryVariables = Exact<{
  filter: SearchSuggestionAll;
}>;


export type SearchSuggestionQuery = { searchSuggestionsAll: Array<{ likes: number, dislikes: number, suggestion: { id: string, description: string, status: string, answer?: string | null | undefined, answeredAt?: any | null | undefined, createdAt: any, updatedAt: any, score?: number | null | undefined, justificationScore?: string | null | undefined, file?: Array<{ id: string, url: string, type: string, createdAt: any, updatedAt: any }> | null | undefined, country?: { flag: string, name: string } | null | undefined, answerBy?: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined } | null | undefined, createdBy: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined }, product: { id: string, name: string, managers?: Array<{ id: string, user?: { name?: string | null | undefined } | null | undefined }> | null | undefined, productLine?: { id: string, name: string } | null | undefined } } }> };

export type GetSuggestionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetSuggestionQuery = { suggestion: { id: string, description: string, status: string, answer?: string | null | undefined, answeredAt?: any | null | undefined, createdAt: any, updatedAt: any, score?: number | null | undefined, justificationScore?: string | null | undefined, file?: Array<{ id: string, url: string, type: string, createdAt: any, updatedAt: any }> | null | undefined, country?: { flag: string, name: string } | null | undefined, answerBy?: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined } | null | undefined, createdBy: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined }, product: { id: string, name: string, managers?: Array<{ id: string, user?: { name?: string | null | undefined } | null | undefined }> | null | undefined, productLine?: { id: string, name: string } | null | undefined } } };

export type SuggestionLikesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type SuggestionLikesQuery = { suggestionLikes: { likes: number, dislikes: number, suggestion: { id: string, description: string, status: string, answer?: string | null | undefined, answeredAt?: any | null | undefined, createdAt: any, updatedAt: any, score?: number | null | undefined, justificationScore?: string | null | undefined, file?: Array<{ id: string, url: string, type: string, createdAt: any, updatedAt: any }> | null | undefined, country?: { flag: string, name: string } | null | undefined, answerBy?: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined } | null | undefined, createdBy: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined }, product: { id: string, name: string, managers?: Array<{ id: string, user?: { name?: string | null | undefined } | null | undefined }> | null | undefined, productLine?: { id: string, name: string } | null | undefined } } } };

export type RemoveSuggestionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveSuggestionMutation = { removeSuggestion: { message: string, status: number } };

export type AnswerSuggestionMutationVariables = Exact<{
  input: AnswerSuggestionInput;
}>;


export type AnswerSuggestionMutation = { answerSuggestion: { id: string, description: string, status: string, answer?: string | null | undefined, answeredAt?: any | null | undefined, createdAt: any, updatedAt: any, score?: number | null | undefined, justificationScore?: string | null | undefined, file?: Array<{ id: string, url: string, type: string, createdAt: any, updatedAt: any }> | null | undefined, country?: { flag: string, name: string } | null | undefined, answerBy?: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined } | null | undefined, createdBy: { id: string, name?: string | null | undefined, photo?: string | null | undefined, profile?: string | null | undefined, unity?: { id: string, name: string } | null | undefined }, product: { id: string, name: string, managers?: Array<{ id: string, user?: { name?: string | null | undefined } | null | undefined }> | null | undefined, productLine?: { id: string, name: string } | null | undefined } } };

export type GetUnitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnitiesQuery = { unities: Array<{ id: string, name: string }> };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { currentUser: { id: string, name?: string | null | undefined, email?: string | null | undefined, re: string, profile?: string | null | undefined, photo?: string | null | undefined, status?: string | null | undefined, unity?: { id: string, name: string } | null | undefined, countries: Array<{ id: string, name: string, flag: string }> } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { users: Array<{ id: string, name?: string | null | undefined, email?: string | null | undefined, re: string, photo?: string | null | undefined, profile?: string | null | undefined, status?: string | null | undefined, countries: Array<{ id: string, name: string, flag: string }>, unity?: { id: string, name: string } | null | undefined }> };

export type SearchUserAllQueryVariables = Exact<{
  input: SearchUserAll;
}>;


export type SearchUserAllQuery = { searchUsersAll: Array<{ id: string, name?: string | null | undefined, email?: string | null | undefined, re: string, photo?: string | null | undefined, profile?: string | null | undefined, status?: string | null | undefined, countries: Array<{ id: string, name: string, flag: string }>, unity?: { id: string, name: string } | null | undefined }> };

export type SearchUserQueryVariables = Exact<{
  input: SearchUserAll;
}>;


export type SearchUserQuery = { searchUsers: { currentPage: number, nextPage: boolean, pageSize: number, previousPage: boolean, totalCount: number, totalPage: number, results: Array<{ id: string, name?: string | null | undefined, email?: string | null | undefined, re: string, photo?: string | null | undefined, profile?: string | null | undefined, status?: string | null | undefined, countries: Array<{ id: string, name: string, flag: string }>, unity?: { id: string, name: string } | null | undefined }> } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { createUser: { id: string, name?: string | null | undefined, email?: string | null | undefined, re: string, photo?: string | null | undefined, profile?: string | null | undefined, status?: string | null | undefined, countries: Array<{ id: string, name: string, flag: string }>, unity?: { id: string, name: string } | null | undefined } };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { updateUser: { id: string, name?: string | null | undefined, email?: string | null | undefined, re: string, photo?: string | null | undefined, profile?: string | null | undefined, status?: string | null | undefined, countries: Array<{ id: string, name: string, flag: string }>, unity?: { id: string, name: string } | null | undefined } };

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveUserMutation = { removeUser: { message: string } };

export type GetNotificationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationQuery = { userNotifications: { count: number, notifications: Array<{ id: string, title: string, message: string, type?: string | null | undefined, relationId?: string | null | undefined, isRead: boolean }> } };

export type ChangeNotificationViewMutationVariables = Exact<{
  id: Scalars['String'];
  status: Scalars['Boolean'];
}>;


export type ChangeNotificationViewMutation = { viewNotification: { id: string, title: string, message: string, type?: string | null | undefined, relationId?: string | null | undefined, isRead: boolean } };

export const CountryFragmentDoc = gql`
    fragment Country on Country {
  id
  name
  flag
}
    `;
export const BudgetFragmentDoc = gql`
    fragment Budget on Budget {
  id
  history {
    id
    status
    createdAt
    updatedAt
    createdBy {
      id
      name
      email
      re
      profile
      photo
    }
  }
  city {
    id
    name
    country {
      name
      flag
    }
  }
  createdBy {
    name
  }
  speciality {
    id
    name
  }
  product {
    id
    name
    productLine {
      id
      name
    }
  }
  date
  institution
  quantity
  isCorporate
  shareValue
  description
  response
  status
  createdAt
  updatedAt
}
    `;
export const CompetenceFragmentDoc = gql`
    fragment Competence on Competence {
  id
  country {
    name
    flag
  }
  product {
    id
    name
    managers {
      id
      user {
        id
        name
      }
    }
    productLine {
      id
      name
    }
  }
  createdBy {
    name
    countries {
      name
      flag
    }
    profile
  }
  answeredAt
  answerBy {
    name
    profile
  }
  file {
    id
    url
    type
  }
  answer
  status
  description
  createdAt
  updatedAt
  score
  justificationScore
}
    `;
export const StatisticDashboardFragmentDoc = gql`
    fragment StatisticDashboard on StatisticDashboard {
  consult
  suggestion
  competence
  budget
}
    `;
export const ConsultFileFragmentDoc = gql`
    fragment ConsultFile on ConsultFile {
  id
  url
  type
  createdAt
  updatedAt
}
    `;
export const ConsultFragmentDoc = gql`
    fragment Consult on Consult {
  id
  description
  status
  answer
  answeredAt
  createdAt
  updatedAt
  file {
    ...ConsultFile
  }
  country {
    flag
    name
  }
  answerBy {
    id
    name
    photo
    profile
    unity {
      id
      name
    }
  }
  createdBy {
    id
    name
    photo
    profile
    unity {
      id
      name
    }
  }
  product {
    id
    name
    productLine {
      id
      name
    }
  }
}
    ${ConsultFileFragmentDoc}`;
export const UnityFragmentDoc = gql`
    fragment Unity on Unity {
  id
  name
}
    `;
export const CountriesFragmentDoc = gql`
    fragment Countries on Country {
  id
  name
  flag
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  email
  re
  profile
  photo
  status
  unity {
    ...Unity
  }
  countries {
    ...Countries
  }
}
    ${UnityFragmentDoc}
${CountriesFragmentDoc}`;
export const ConsultLikeFragmentDoc = gql`
    fragment ConsultLike on ConsultLike {
  id
  like
  consult {
    ...Consult
  }
  createdBy {
    ...User
  }
  createdAt
}
    ${ConsultFragmentDoc}
${UserFragmentDoc}`;
export const ConsultResponseDtoFragmentDoc = gql`
    fragment ConsultResponseDto on ConsultResponseDto {
  consult {
    ...Consult
  }
  dislikes
  likes
}
    ${ConsultFragmentDoc}`;
export const SuggestionFileFragmentDoc = gql`
    fragment SuggestionFile on SuggestionFile {
  id
  url
  type
  createdAt
  updatedAt
}
    `;
export const SuggestionFragmentDoc = gql`
    fragment Suggestion on Suggestion {
  id
  description
  status
  answer
  answeredAt
  createdAt
  updatedAt
  file {
    ...SuggestionFile
  }
  country {
    flag
    name
  }
  answerBy {
    id
    name
    photo
    profile
    unity {
      id
      name
    }
  }
  createdBy {
    id
    name
    photo
    profile
    unity {
      id
      name
    }
  }
  product {
    id
    name
    managers {
      id
      user {
        name
      }
    }
    productLine {
      id
      name
    }
  }
  score
  justificationScore
}
    ${SuggestionFileFragmentDoc}`;
export const SuggestionResponseDtoFragmentDoc = gql`
    fragment SuggestionResponseDto on SuggestionResponseDto {
  suggestion {
    ...Suggestion
  }
  dislikes
  likes
}
    ${SuggestionFragmentDoc}`;
export const SearchBudgetsDocument = gql`
    query SearchBudgets($filter: SearchBudgetAll!) {
  searchBudgets(filter: $filter) {
    currentPage
    nextPage
    pageSize
    previousPage
    totalCount
    totalPage
    results {
      id
      date
      createdBy {
        name
      }
      city {
        name
        country {
          id
          name
          flag
        }
      }
      shareValue
      status
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchBudgetsGQL extends Apollo.Query<SearchBudgetsQuery, SearchBudgetsQueryVariables> {
    document = SearchBudgetsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductsDocument = gql`
    query Products {
  products {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductsGQL extends Apollo.Query<ProductsQuery, ProductsQueryVariables> {
    document = ProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductsByLinesDocument = gql`
    query ProductsByLines($lines: [String!]) {
  productsByLines(lines: $lines) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductsByLinesGQL extends Apollo.Query<ProductsByLinesQuery, ProductsByLinesQueryVariables> {
    document = ProductsByLinesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CitiesDocument = gql`
    query Cities {
  cities {
    id
    name
    country {
      flag
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CitiesGQL extends Apollo.Query<CitiesQuery, CitiesQueryVariables> {
    document = CitiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BudgetDocument = gql`
    query Budget($id: String!) {
  budget(id: $id) {
    ...Budget
  }
}
    ${BudgetFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BudgetGQL extends Apollo.Query<BudgetQuery, BudgetQueryVariables> {
    document = BudgetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveBudgetDocument = gql`
    mutation RemoveBudget($id: String!) {
  removeBudget(id: $id) {
    message
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveBudgetGQL extends Apollo.Mutation<RemoveBudgetMutation, RemoveBudgetMutationVariables> {
    document = RemoveBudgetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBudgetStatusDocument = gql`
    mutation UpdateBudgetStatus($input: UpdateBudgetStatusInput!) {
  updateBudgetStatus(data: $input) {
    ...Budget
  }
}
    ${BudgetFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBudgetStatusGQL extends Apollo.Mutation<UpdateBudgetStatusMutation, UpdateBudgetStatusMutationVariables> {
    document = UpdateBudgetStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchCompetenceAllDocument = gql`
    query SearchCompetenceAll($filter: SearchCompetenceAll!) {
  searchCompetenceAll(filters: $filter) {
    ...Competence
  }
}
    ${CompetenceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchCompetenceAllGQL extends Apollo.Query<SearchCompetenceAllQuery, SearchCompetenceAllQueryVariables> {
    document = SearchCompetenceAllDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompetenceDocument = gql`
    query Competence($id: String!) {
  competence(id: $id) {
    ...Competence
  }
}
    ${CompetenceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CompetenceGQL extends Apollo.Query<CompetenceQuery, CompetenceQueryVariables> {
    document = CompetenceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveCompetenceDocument = gql`
    mutation RemoveCompetence($id: String!) {
  removeCompetence(id: $id) {
    message
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveCompetenceGQL extends Apollo.Mutation<RemoveCompetenceMutation, RemoveCompetenceMutationVariables> {
    document = RemoveCompetenceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AnswerCompetenceDocument = gql`
    mutation AnswerCompetence($input: answerCompetenceInput!) {
  answerCompetence(data: $input) {
    ...Competence
  }
}
    ${CompetenceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AnswerCompetenceGQL extends Apollo.Mutation<AnswerCompetenceMutation, AnswerCompetenceMutationVariables> {
    document = AnswerCompetenceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetConsultDocument = gql`
    query GetConsult($id: String!) {
  consult(id: $id) {
    id
    answerBy {
      id
      name
    }
    status
    description
    answer
    answeredAt
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetConsultGQL extends Apollo.Query<GetConsultQuery, GetConsultQueryVariables> {
    document = GetConsultDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetConsultLikesDocument = gql`
    query GetConsultLikes($id: String!) {
  consultLikes(id: $id) {
    ...ConsultResponseDto
  }
}
    ${ConsultResponseDtoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetConsultLikesGQL extends Apollo.Query<GetConsultLikesQuery, GetConsultLikesQueryVariables> {
    document = GetConsultLikesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetConsultsDocument = gql`
    query GetConsults {
  consults {
    id
    answerBy {
      id
      name
    }
    country {
      flag
      name
    }
    status
    description
    answer
    answeredAt
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetConsultsGQL extends Apollo.Query<GetConsultsQuery, GetConsultsQueryVariables> {
    document = GetConsultsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchConsultAllDocument = gql`
    query SearchConsultAll($input: SearchConsultAll!) {
  searchConsultAll(filter: $input) {
    consult {
      id
      description
      status
      answer
      answeredAt
      createdAt
      updatedAt
      country {
        flag
        name
      }
      answerBy {
        id
        name
      }
      createdBy {
        id
        name
      }
      product {
        id
        name
        productLine {
          id
          name
        }
      }
    }
    dislikes
    likes
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchConsultAllGQL extends Apollo.Query<SearchConsultAllQuery, SearchConsultAllQueryVariables> {
    document = SearchConsultAllDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateConsultDocument = gql`
    mutation CreateConsult($input: CreateConsultInput!) {
  createConsult(data: $input) {
    id
    answerBy {
      id
      name
    }
    status
    description
    answer
    answeredAt
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateConsultGQL extends Apollo.Mutation<CreateConsultMutation, CreateConsultMutationVariables> {
    document = CreateConsultDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateConsultDocument = gql`
    mutation updateConsult($input: UpdateConsultInput!) {
  updateConsult(data: $input) {
    id
    answerBy {
      id
      name
    }
    status
    description
    answer
    answeredAt
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateConsultGQL extends Apollo.Mutation<UpdateConsultMutation, UpdateConsultMutationVariables> {
    document = UpdateConsultDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AnswerConsultDocument = gql`
    mutation answerConsult($input: AnswerConsultInput!) {
  answerConsult(data: $input) {
    id
    answer
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AnswerConsultGQL extends Apollo.Mutation<AnswerConsultMutation, AnswerConsultMutationVariables> {
    document = AnswerConsultDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveConsultUserDocument = gql`
    mutation RemoveConsultUser($id: String!) {
  removeUser(id: $id) {
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveConsultUserGQL extends Apollo.Mutation<RemoveConsultUserMutation, RemoveConsultUserMutationVariables> {
    document = RemoveConsultUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveConsultDocument = gql`
    mutation RemoveConsult($id: String!) {
  removeConsult(id: $id) {
    message
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveConsultGQL extends Apollo.Mutation<RemoveConsultMutation, RemoveConsultMutationVariables> {
    document = RemoveConsultDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCountriesDocument = gql`
    query GetCountries {
  countries {
    id
    name
    flag
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCountriesGQL extends Apollo.Query<GetCountriesQuery, GetCountriesQueryVariables> {
    document = GetCountriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCountriesByUserIdDocument = gql`
    query GetCountriesByUserID($id: String!) {
  countriesByUser(id: $id) {
    id
    name
    flag
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCountriesByUserIdGQL extends Apollo.Query<GetCountriesByUserIdQuery, GetCountriesByUserIdQueryVariables> {
    document = GetCountriesByUserIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCountriesByUserDocument = gql`
    query GetCountriesByUser {
  countriesByUser {
    id
    name
    flag
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCountriesByUserGQL extends Apollo.Query<GetCountriesByUserQuery, GetCountriesByUserQueryVariables> {
    document = GetCountriesByUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCitiesByCountriesDocument = gql`
    query GetCitiesByCountries($ids: [String!]!) {
  citiesByCountries(ids: $ids) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCitiesByCountriesGQL extends Apollo.Query<GetCitiesByCountriesQuery, GetCitiesByCountriesQueryVariables> {
    document = GetCitiesByCountriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatisticCountDocument = gql`
    query StatisticCount($input: SearchStatisticInput!) {
  statisticCount(data: $input) {
    ...StatisticDashboard
  }
}
    ${StatisticDashboardFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatisticCountGQL extends Apollo.Query<StatisticCountQuery, StatisticCountQueryVariables> {
    document = StatisticCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TopDocument = gql`
    query Top($input: SearchStatisticInput!) {
  topBudgetCount(data: $input) {
    name
    count
  }
  topConsultCount(data: $input) {
    name
    count
  }
  topCompetenceCount(data: $input) {
    name
    count
  }
  topSuggestionCount(data: $input) {
    name
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TopGQL extends Apollo.Query<TopQuery, TopQueryVariables> {
    document = TopDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CardDocument = gql`
    query Card($input: SearchStatisticInput!) {
  cardConsultCount(data: $input) {
    pending
    answered
    positive
    negative
  }
  cardSuggestionCount(data: $input) {
    pending
    answered
    positive
    negative
  }
  cardCompetenceCount(data: $input) {
    pending
    answered
  }
  cardBudgetCount(data: $input) {
    approved
    pending
    revision
    disapproved
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CardGQL extends Apollo.Query<CardQuery, CardQueryVariables> {
    document = CardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListQuartersDocument = gql`
    query ListQuarters {
  listQuarters {
    quarter
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListQuartersGQL extends Apollo.Query<ListQuartersQuery, ListQuartersQueryVariables> {
    document = ListQuartersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductLinesDocument = gql`
    query ProductLines {
  productLines {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductLinesGQL extends Apollo.Query<ProductLinesQuery, ProductLinesQueryVariables> {
    document = ProductLinesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRankingDocument = gql`
    query GetRanking($quarter: String!, $productLineId: String!) {
  getRanking(quarter: $quarter, productLineId: $productLineId) {
    position
    name
    totalScore
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRankingGQL extends Apollo.Query<GetRankingQuery, GetRankingQueryVariables> {
    document = GetRankingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUnScoredTotalDocument = gql`
    query getUnScoredTotal($quarter: String!, $productLineId: String!) {
  getUnScoredTotal(quarter: $quarter, productLineId: $productLineId) {
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUnScoredTotalGQL extends Apollo.Query<GetUnScoredTotalQuery, GetUnScoredTotalQueryVariables> {
    document = GetUnScoredTotalDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRankingByCountryDocument = gql`
    query getRankingByCountry($quarter: String!) {
  getRankingByCountry(quarter: $quarter) {
    productLine
    percent
    ranking {
      totalScore
      name
      position
    }
    totalAnswered
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRankingByCountryGQL extends Apollo.Query<GetRankingByCountryQuery, GetRankingByCountryQueryVariables> {
    document = GetRankingByCountryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchProductLineDocument = gql`
    query SearchProductLine($input: SearchProductLine!) {
  searchProductLine(filters: $input) {
    id
    name
    countries {
      id
      name
      flag
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchProductLineGQL extends Apollo.Query<SearchProductLineQuery, SearchProductLineQueryVariables> {
    document = SearchProductLineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchProductLinePageDocument = gql`
    query SearchProductLinePage($input: SearchProductLine!) {
  searchProductLinePage(filters: $input) {
    currentPage
    nextPage
    pageSize
    previousPage
    totalCount
    totalPage
    results {
      id
      name
      countries {
        id
        name
        flag
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchProductLinePageGQL extends Apollo.Query<SearchProductLinePageQuery, SearchProductLinePageQueryVariables> {
    document = SearchProductLinePageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchProductLinesByCountryDocument = gql`
    query SearchProductLinesByCountry($id: [String!]) {
  searchProductLinesByCountry(countryId: $id) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchProductLinesByCountryGQL extends Apollo.Query<SearchProductLinesByCountryQuery, SearchProductLinesByCountryQueryVariables> {
    document = SearchProductLinesByCountryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductLinesDocument = gql`
    query GetProductLines {
  productLines {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductLinesGQL extends Apollo.Query<GetProductLinesQuery, GetProductLinesQueryVariables> {
    document = GetProductLinesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateLineDocument = gql`
    mutation CreateLine($input: CreateProductLineInput!) {
  createProductLine(data: $input) {
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateLineGQL extends Apollo.Mutation<CreateLineMutation, CreateLineMutationVariables> {
    document = CreateLineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateLineDocument = gql`
    mutation UpdateLine($input: UpdateProductLineInput!) {
  updateProductLine(data: $input) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateLineGQL extends Apollo.Mutation<UpdateLineMutation, UpdateLineMutationVariables> {
    document = UpdateLineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveLineDocument = gql`
    mutation RemoveLine($id: String!) {
  removeProductLine(id: $id) {
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveLineGQL extends Apollo.Mutation<RemoveLineMutation, RemoveLineMutationVariables> {
    document = RemoveLineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation Login($code: String!, $redirectUri: String!, $email: String!) {
  login(data: {code: $code, redirectUri: $redirectUri, email: $email}) {
    user {
      id
      name
      email
      re
      photo
      profile
      status
      createdBy {
        id
        name
      }
      createdAt
      deletedAt
      updatedAt
    }
    token
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchProductsAllDocument = gql`
    query SearchProductsAll($filter: SearchProductAll!) {
  searchProductsAll(filter: $filter) {
    id
    name
    productLine {
      id
      name
    }
    managers {
      id
      type
      user {
        id
        name
        email
      }
    }
    countries {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchProductsAllGQL extends Apollo.Query<SearchProductsAllQuery, SearchProductsAllQueryVariables> {
    document = SearchProductsAllDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchProductsDocument = gql`
    query SearchProducts($filter: SearchProduct!) {
  searchProduct(filter: $filter) {
    currentPage
    nextPage
    pageSize
    previousPage
    totalCount
    totalPage
    results {
      id
      name
      productLine {
        id
        name
      }
      managers {
        country {
          id
          name
        }
        id
        type
        user {
          id
          name
          email
          profile
        }
      }
      countries {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchProductsGQL extends Apollo.Query<SearchProductsQuery, SearchProductsQueryVariables> {
    document = SearchProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductsDocument = gql`
    query GetProducts {
  products {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductsGQL extends Apollo.Query<GetProductsQuery, GetProductsQueryVariables> {
    document = GetProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductsByLineDocument = gql`
    query GetProductsByLine($lines: [String!]) {
  productsByLines(lines: $lines) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductsByLineGQL extends Apollo.Query<GetProductsByLineQuery, GetProductsByLineQueryVariables> {
    document = GetProductsByLineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserByProfileDocument = gql`
    query GetUserByProfile($profile: String!) {
  findUsersByProfile(profile: $profile) {
    id
    name
    email
    profile
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserByProfileGQL extends Apollo.Query<GetUserByProfileQuery, GetUserByProfileQueryVariables> {
    document = GetUserByProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductByIdDocument = gql`
    query GetProductById($id: String!) {
  product(id: $id) {
    id
    name
    productLine {
      id
      name
    }
    managers {
      id
      type
      user {
        id
        name
        email
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductByIdGQL extends Apollo.Query<GetProductByIdQuery, GetProductByIdQueryVariables> {
    document = GetProductByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveProductDocument = gql`
    mutation RemoveProduct($id: String!) {
  removeProduct(id: $id) {
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveProductGQL extends Apollo.Mutation<RemoveProductMutation, RemoveProductMutationVariables> {
    document = RemoveProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProductDocument = gql`
    mutation UpdateProduct($input: UpdateProductInput!) {
  updateProduct(data: $input) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProductGQL extends Apollo.Mutation<UpdateProductMutation, UpdateProductMutationVariables> {
    document = UpdateProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProductDocument = gql`
    mutation CreateProduct($input: CreateProductInput!) {
  createProduct(data: $input) {
    id
    name
    productLine {
      id
      name
    }
    managers {
      id
      type
      user {
        id
        name
        countries {
          id
          name
          flag
        }
        email
        profile
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProductGQL extends Apollo.Mutation<CreateProductMutation, CreateProductMutationVariables> {
    document = CreateProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProductManagerDocument = gql`
    mutation CreateProductManager($input: CreateProductManagerInput!) {
  createProductManager(data: $input) {
    id
    type
    user {
      id
      name
      email
    }
    country {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProductManagerGQL extends Apollo.Mutation<CreateProductManagerMutation, CreateProductManagerMutationVariables> {
    document = CreateProductManagerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveProductManagerDocument = gql`
    mutation RemoveProductManager($id: String!) {
  removeProductManager(id: $id) {
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveProductManagerGQL extends Apollo.Mutation<RemoveProductManagerMutation, RemoveProductManagerMutationVariables> {
    document = RemoveProductManagerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchSuggestionDocument = gql`
    query SearchSuggestion($filter: SearchSuggestionAll!) {
  searchSuggestionsAll(filter: $filter) {
    likes
    dislikes
    suggestion {
      ...Suggestion
    }
  }
}
    ${SuggestionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchSuggestionGQL extends Apollo.Query<SearchSuggestionQuery, SearchSuggestionQueryVariables> {
    document = SearchSuggestionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSuggestionDocument = gql`
    query GetSuggestion($id: String!) {
  suggestion(id: $id) {
    ...Suggestion
  }
}
    ${SuggestionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSuggestionGQL extends Apollo.Query<GetSuggestionQuery, GetSuggestionQueryVariables> {
    document = GetSuggestionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SuggestionLikesDocument = gql`
    query SuggestionLikes($id: String!) {
  suggestionLikes(id: $id) {
    suggestion {
      ...Suggestion
    }
    likes
    dislikes
  }
}
    ${SuggestionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SuggestionLikesGQL extends Apollo.Query<SuggestionLikesQuery, SuggestionLikesQueryVariables> {
    document = SuggestionLikesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveSuggestionDocument = gql`
    mutation RemoveSuggestion($id: String!) {
  removeSuggestion(id: $id) {
    message
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveSuggestionGQL extends Apollo.Mutation<RemoveSuggestionMutation, RemoveSuggestionMutationVariables> {
    document = RemoveSuggestionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AnswerSuggestionDocument = gql`
    mutation AnswerSuggestion($input: AnswerSuggestionInput!) {
  answerSuggestion(data: $input) {
    ...Suggestion
  }
}
    ${SuggestionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AnswerSuggestionGQL extends Apollo.Mutation<AnswerSuggestionMutation, AnswerSuggestionMutationVariables> {
    document = AnswerSuggestionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUnitiesDocument = gql`
    query GetUnities {
  unities {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUnitiesGQL extends Apollo.Query<GetUnitiesQuery, GetUnitiesQueryVariables> {
    document = GetUnitiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    id
    name
    email
    re
    photo
    profile
    status
    countries {
      id
      name
      flag
    }
    unity {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersGQL extends Apollo.Query<GetUsersQuery, GetUsersQueryVariables> {
    document = GetUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchUserAllDocument = gql`
    query SearchUserAll($input: SearchUserAll!) {
  searchUsersAll(filter: $input) {
    id
    name
    email
    re
    photo
    profile
    status
    countries {
      id
      name
      flag
    }
    unity {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchUserAllGQL extends Apollo.Query<SearchUserAllQuery, SearchUserAllQueryVariables> {
    document = SearchUserAllDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchUserDocument = gql`
    query SearchUser($input: SearchUserAll!) {
  searchUsers(filter: $input) {
    currentPage
    nextPage
    pageSize
    previousPage
    totalCount
    totalPage
    results {
      id
      name
      email
      re
      photo
      profile
      status
      countries {
        id
        name
        flag
      }
      unity {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchUserGQL extends Apollo.Query<SearchUserQuery, SearchUserQueryVariables> {
    document = SearchUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(data: $input) {
    id
    name
    email
    re
    photo
    profile
    status
    countries {
      id
      name
      flag
    }
    unity {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(data: $input) {
    id
    name
    email
    re
    photo
    profile
    status
    countries {
      id
      name
      flag
    }
    unity {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveUserDocument = gql`
    mutation RemoveUser($id: String!) {
  removeUser(id: $id) {
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveUserGQL extends Apollo.Mutation<RemoveUserMutation, RemoveUserMutationVariables> {
    document = RemoveUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetNotificationDocument = gql`
    query GetNotification {
  userNotifications {
    count
    notifications {
      id
      title
      message
      type
      relationId
      isRead
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetNotificationGQL extends Apollo.Query<GetNotificationQuery, GetNotificationQueryVariables> {
    document = GetNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangeNotificationViewDocument = gql`
    mutation ChangeNotificationView($id: String!, $status: Boolean!) {
  viewNotification(data: {id: $id, isRead: $status}) {
    id
    title
    message
    type
    relationId
    isRead
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeNotificationViewGQL extends Apollo.Mutation<ChangeNotificationViewMutation, ChangeNotificationViewMutationVariables> {
    document = ChangeNotificationViewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }